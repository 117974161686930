// adyen dropin logic were implemented using this manual https://docs.adyen.com/online-payments/web-drop-in/integrated-before-5-0-0
import React from 'react'
import styled from 'styled-components'
import '@adyen/adyen-web/dist/adyen.css'
import { useTranslation } from 'react-i18next'
import '@adyen/adyen-web/dist/adyen.css'
import Text from 'components/atoms/text'
import { useAdyen } from './useAdyen.js'
import { PaypalButton } from './paypalButton.js'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo.js'
import { OverlayLoader } from 'components/atoms/overlay-loader/index.js'
import { breakpoints } from 'helpers/style-constants.js'

export const StyledAdyenPaymentContainer = styled.div`
  margin: 30px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .paypal-wrapper {
    display: none;
  }

  &:has(.adyen-checkout__dropin--ready) {
    .paypal-wrapper {
      display: block;
    }
  }

  .adyen-checkout__button--pay {
    background-color: #90c53c;
    border-radius: 0;
    max-width: 300px;

    @media (max-width: ${breakpoints.md}) {
      max-width: 100%;
    }
  }

  .adyen-checkout__payment-method {
    background: transparent;
    padding: 0 2px;
    border: none;
  }

  .adyen-checkout__payment-method__details {
    padding: 0;
  }

  .adyen-checkout__label__text {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 12px;
    color: #00112c;
  }

  .adyen-checkout__input {
    border-radius: 0;
    border: none;
    height: 52px;
    width: 100%;
    background: #ffffff;
    color: black;
    font-size: 16px;
    cursor: pointer;
    padding: 16px 28px;
    box-sizing: border-box !important;
    border: 1px solid rgba(0, 0, 0, 0.23);

    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }

    &:active,
    &:focus,
    &--focus {
      border-color: #90c53c !important;
      box-shadow: 0 0 0 2px #badb85;
    }
  }

  .adyen-checkout__field--consentCheckbox
    .adyen-checkout-input__inline-validation {
    right: 0px !important;
  }

  .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
    border-right: 2px solid white;
  }
`

const Adyen = ({ redirectUrl }) => {
  const { t } = useTranslation()
  const { adyen, loading, paymentContainer, handleClickPaypal, paypalLoading } =
    useAdyen({ redirectUrl })
  const { store } = useCheckoutInfo()

  return (
    <>
      {adyen && <Text size={2}> {t('checkout.select-method')}</Text>}
      <StyledAdyenPaymentContainer id="adyen" ref={paymentContainer}>
        {store.paypalEnabled && adyen && (
          <PaypalButton
            onClick={handleClickPaypal}
            loading={paypalLoading}
            adyen={adyen}
          />
        )}
      </StyledAdyenPaymentContainer>

      {loading && <OverlayLoader />}
    </>
  )
}

export default Adyen

import React from 'react'
import { ProvideCheckoutInfo } from 'contexts/plan'
import { ProvideNotify } from 'contexts/notify'
import { ProvideLanguage } from 'contexts/language'
import { ProvideCustomer } from 'contexts/customer'
import { ProvideSubscriptions } from 'contexts/subscriptions'
import { ProvideGlobalLoader } from 'contexts/globalLoader'
import ProtectedRoute from '../app/protected-route'

function App() {
  return (
    <ProvideGlobalLoader>
      <ProvideCustomer>
        <ProvideNotify>
          <ProvideCheckoutInfo>
            <ProvideLanguage>
              <ProvideSubscriptions>
                <ProtectedRoute />
              </ProvideSubscriptions>
            </ProvideLanguage>
          </ProvideCheckoutInfo>
        </ProvideNotify>
      </ProvideCustomer>
    </ProvideGlobalLoader>
  )
}

export default App

import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './components/app'
import './locales/i18n'
import './index.css'
import theme from './theme'
import ErrorFallback from 'components/pages/error'
import { Helmet } from 'react-helmet'

const deployed = new Set([
  'checkout-tst.fitformedev.nl',
  'checkout.fitforme.com',
])

if (deployed.has(window.location.hostname)) {
  Sentry.init({
    dsn: 'https://f054f02ecfe54ecc9fee1715e0f7bfcc@o927224.ingest.sentry.io/6217531',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, // 1.0 - capture 100% of transactions.
    environment: window.location.hostname,
    ignoreErrors: ['3ds card payment failed', '429'],
  })
}

const container = document.getElementById('root')

const isProduction = window.location.hostname === 'checkout.fitforme.com'

const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      {isProduction && (
        <Helmet>
          {/* <!-- Google Tag Manager --> */}
          <script>
            {(function (w, d, s, l, i) {
              w[l] = w[l] || []
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : ''
              j.async = true
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
              f.parentNode.insertBefore(j, f)
            })(window, document, 'script', 'dataLayer', 'GTM-PJFZ2CH')}
          </script>
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>
      )}
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

import React, { useMemo, useEffect } from 'react'
import styled from 'styled-components'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import Chargebee from './chargebee'
import Adyen from './adyen'
import WithoutPayment from './without-payment'
import { isProd } from 'helpers/helper'
import Tooltip from '@mui/material/Tooltip'
import useCustomer from 'hooks/customer/useCustomer'

const PaymentFlowIndicator = styled.div`
  color: red;
  font-size: 33px;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 99999;
  text-transform: uppercase;
`

const PaymentComponents = { adyen: Adyen, chargebee: Chargebee }

const CreateSubscription = () => {
  const { totalPrice } = useSubscriptions()
  const { paymentFlow } = useCustomer()
  const isFree = useMemo(() => totalPrice === 0, [totalPrice])

  const redirectUrl = `${window.location.protocol}//${window.location.host}/thanks${window.location.search}`

  const PaymentComponent = useMemo(() => {
    if (isFree) {
      return WithoutPayment
    }

    return PaymentComponents[paymentFlow] || Chargebee
  }, [paymentFlow, isFree])

  useEffect(() => {
    // handle https://github.com/Adyen/adyen-web/issues/2309

    setInterval(() => {
      try {
        const list = document.querySelectorAll('#adyen [data-disabled="false"]')
        list.forEach((el) => el.removeAttribute('data-disabled'))
      } catch (e) {
        console.error('Error encountered:', e)
      }
    }, 1000)
  }, [])

  return (
    <>
      {!isProd() && !!paymentFlow && (
        <Tooltip title="this element will not be shown on checkout.fitforme.com">
          <PaymentFlowIndicator>{paymentFlow} flow</PaymentFlowIndicator>
        </Tooltip>
      )}
      <PaymentComponent redirectUrl={redirectUrl} />
    </>
  )
}

export default CreateSubscription

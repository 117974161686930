import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Modal from '@mui/material/Modal'
import { Button } from 'ffm-ui'
import Text from 'components/atoms/text'
import Box from '@mui/material/Box'
import { breakpoints } from 'helpers/style-constants'

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  max-width: 600px;
  min-width: 400px;
  max-height: calc(100vh - 60px);
  box-shadow: 0px 9px 13px -6px rgba(0, 0, 0, 0.21);
  border: 1px solid #dcdcdc;

  @media (max-width: ${breakpoints.sm}) {
    overflow: scroll;
    border: none;
    padding: 20px;
    width: calc(100vw - 40px);
    min-width: auto;
    justify-content: center;
  }
`

const Buttons = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: minmax(100px, 250px) minmax(100px, 250px);
  margin-top: 40px;
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`

const PromptModal = ({ open, onConfirm, onCancel }) => {
  const { t } = useTranslation()

  return (
    <Modal open={!!open} onClose={onCancel}>
      <StyledBox>
        <Texts>
          <Text size={1}>{t('form.changes-not-saved')}</Text>
          <Text size={2}>{t('form.confirm-leave-without-saving')}</Text>
        </Texts>
        <Buttons>
          <Button color="gray" onClick={onCancel}>
            {t('confirm.no')}
          </Button>
          <Button onClick={onConfirm}>{t('confirm.yes')}</Button>
        </Buttons>
      </StyledBox>
    </Modal>
  )
}

export default PromptModal

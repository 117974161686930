import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { CouponForm } from '../coupon-form'
import Subscriptions from 'components/organisms/subscriptions'
import { formatPrice } from 'helpers/format'
import LinearProgress from '@mui/material/LinearProgress'
import { formatDate } from 'helpers/format'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 30px;
`

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`

const Label = styled(Text)`
  color: ${(props) => (props.green ? '#90C53C' : '#000000')};
`
const TextBold = styled(Text)`
  font-weight: bold;
`

const Cart = () => {
  const { t } = useTranslation()
  const { currency, sessionSubscriptions } = useCheckoutInfo()
  const { totalPrice, totalBySubscriptionDate } = useSubscriptions()

  return (
    <Wrapper>
      {!!sessionSubscriptions?.length && <Subscriptions />}
      <CouponForm />

      {isNaN(totalPrice) ? (
        <LinearProgress style={{ margin: '10px 0' }} />
      ) : (
        <div>
          {totalBySubscriptionDate &&
            Object.keys(totalBySubscriptionDate).length > 1 && (
              <div>
                {Object.keys(totalBySubscriptionDate)
                  .sort(function (a, b) {
                    if (a === 'Immediately' || b === 'Immediately') {
                      return -1
                    } else {
                      return 1
                    }
                  })
                  .map((key) => {
                    const total = totalBySubscriptionDate[key]?.total

                    if (total) {
                      return (
                        <Footer key={key}>
                          {key === 'Immediately' ? (
                            <Label green size={2}>
                              {t('subscription.time.immediately')}
                            </Label>
                          ) : (
                            <Label size={2}>{formatDate(new Date(key))}</Label>
                          )}

                          <Price>{formatPrice(total / 100, currency)}</Price>
                        </Footer>
                      )
                    }
                    return null
                  })}
              </div>
            )}
          <Footer>
            <TextBold size={2}>{t('cart.total')}</TextBold>
            <Price>{formatPrice(totalPrice / 100, currency)}</Price>
          </Footer>
        </div>
      )}
    </Wrapper>
  )
}

export default Cart

import React from 'react'
import styled from 'styled-components'

import useIsVisible from 'hooks/visible/useIsVisible'
import useLanguage from 'hooks/language/useLanguage'
import { ReactComponent as Arrow } from 'images/arrow.svg'
import { LogoLink } from 'components/atoms/logo-link'

const Wrapper = styled.div`
  padding: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectHolder = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 48px;
  position: absolute;
  right: 15px;
`

const SelectButton = styled.button`
  height: 27px;
  background: ${({ color }) =>
    color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.14)'};
  border-radius: 20px;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px 0 10px;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.18);
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.18)'};
  }

  &:active {
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.3)'};
  }

  &:disabled {
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.4)'};
    cursor: initial;
    pointer-events: none;
  }
`

const ArrowIcon = styled(Arrow)`
  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  transition: transform 150ms ease-in;
  width: 8px;
  height: 4px;
  margin-left: 4px;
  position: absolute;
  right: 8px;
`

const LangItems = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 5px 9px 15px 2px rgba(0, 0, 0, 0.21);
  z-index: 999;
`

const Item = styled.li`
  padding: 10px;
  background: white;
  list-style: none;
  border-bottom: 1px solid #ededed;
  color: black;

  &:last-of-type {
    border: none;
  }

  &:hover {
    background: #90c53c;
    color: white;
  }
`

export const HeaderMobile = () => {
  const { isVisible, setIsVisible, ref } = useIsVisible(false)
  const { listOfLang, lang, setLang } = useLanguage()

  return (
    <Wrapper>
      <LogoLink />
      <SelectHolder ref={ref}>
        <SelectButton
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          color="green"
        >
          {lang}
          <ArrowIcon open={isVisible} />
          {isVisible && (
            <LangItems>
              {Object.keys(listOfLang).map((key) => {
                return (
                  <Item
                    key={key}
                    onClick={() => {
                      setLang(key)
                      setIsVisible(false)
                    }}
                  >
                    {key}
                  </Item>
                )
              })}
            </LangItems>
          )}
        </SelectButton>
      </SelectHolder>
    </Wrapper>
  )
}

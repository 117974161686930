import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'ffm-ui'

import useRequest from 'hooks/request/useRequest'
import Title from 'components/atoms/title'
import Text from 'components/atoms/text'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import useCustomer from 'hooks/customer/useCustomer'
import { isEmail } from 'helpers/helper'
import { LinkButton, AUTH_VIEWS, StyledForm } from '.'
import { sendEvent } from 'helpers/analytics'

const ErrorHolder = styled.span`
  display: flex;
  gap: 5px;
`

export const NewCustomerForm = ({
  email,
  setEmail,
  emailError,
  setEmailError,
  setView,
  visible,
}) => {
  const { t } = useTranslation()
  const { store, setShowAuth } = useCheckoutInfo()
  const { setPersonalDetails } = useCustomer()

  const [isAccountExist, setIsAccountExist] = useState(false)

  const { doRequest: checkIfAccountExists } = useRequest({
    url: '/api/customer/otp/check-email',
    method: 'post',
    body: { email },
    onSuccess: (response) => {
      if (!response?.exist) {
        setEmailError('auth.dont-have-account-yet')
        return false
      }

      setEmailError('')
      return true
    },
  })

  return (
    <StyledForm visible={visible}>
      <Title style={{ margin: 0 }} size={3}>
        {t('auth.what-email')}
      </Title>
      <Input
        required
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          setEmailError('')
        }}
        type="email"
        name="email"
        label={t('user-data.email')}
        placeholder={t('user-data.email')}
        error={
          emailError && (
            <ErrorHolder>
              {t(emailError)}
              {isAccountExist && (
                <LinkButton
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setView(AUTH_VIEWS.EXISTING)
                    setEmailError(false)
                  }}
                >
                  {t('auth.sign-in')}
                </LinkButton>
              )}
            </ErrorHolder>
          )
        }
      />
      <Button
        fullWidth
        disabled={!!emailError}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsAccountExist(false)

          if (isEmail(email)) {
            checkIfAccountExists({ email, store: store.id }).then((res) => {
              if (res?.exist) {
                setEmailError('auth.account-exists')
                setIsAccountExist(true)
              } else {
                setPersonalDetails((prev) => ({ ...prev, email }))
                setEmailError(null)
                setShowAuth(false)

                sendEvent('sign_up')
              }
            })
          } else {
            setEmailError('auth.enter-valid-email')
          }
        }}
        type="submit"
      >
        {t('auth.go-to-next-step')}
      </Button>

      <Text size={2}>
        {t('auth.existing-customer')}&nbsp;
        <LinkButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setView(AUTH_VIEWS.EXISTING)
            setEmailError(false)
          }}
        >
          {t('auth.sign-in')}
        </LinkButton>
      </Text>
    </StyledForm>
  )
}

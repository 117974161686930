import React from 'react'
import '@adyen/adyen-web/dist/adyen.css'
import Text from 'components/atoms/text'
import { useTranslation } from 'react-i18next'
import { StyledAdyenPaymentContainer } from './adyen'
import { useChargebee } from './useChargebee'
import { PaypalButton } from './paypalButton'
import { OverlayLoader } from 'components/atoms/overlay-loader'

const Chargebee = ({ redirectUrl }) => {
  const { t } = useTranslation()
  const { adyen, paymentContainer, paypalLoading, handleClickPaypal } =
    useChargebee({ redirectUrl })

  return (
    <>
      <Text size={2}>{t('checkout.select-method')}</Text>
      <StyledAdyenPaymentContainer id="adyen" ref={paymentContainer}>
        {!!adyen && (
          <PaypalButton
            onClick={handleClickPaypal}
            loading={paypalLoading}
            adyen={adyen}
          />
        )}
      </StyledAdyenPaymentContainer>

      {!adyen && <OverlayLoader />}
    </>
  )
}

export default Chargebee

export function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true
  }

  return false
}

export function isBeforeToday(date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return date < today
}

export const isEmail = (text) => /^\S+@\S+\.\S+$/.test(text)

export const isProd = () => window.location.hostname === 'checkout.fitforme.com'

import React from 'react'
import logo from 'images/logo.svg'
import styled from 'styled-components'
import { breakpoints } from 'helpers/style-constants'

const StyledLink = styled.a`
  text-decoration: none;
  height: 36px;

  @media (max-width: ${breakpoints.md}) {
    height: 28px;
  }

  & img {
    max-height: 100%;
    max-width: 100%;
  }
`

export const LogoLink = () => (
  <StyledLink href="https://fitforme.com" target="_blank" rel="noreferrer">
    <img width={170} src={logo} alt="FitForMe Logo" />
  </StyledLink>
)

import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakpoints } from 'helpers/style-constants'
import Title from 'components/atoms/title'
import Text from 'components/atoms/text'
import { ErrorPageLayout } from 'components/layouts/error'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 100%;
`

const PaperBox = styled(Paper)`
  display: flex;
  min-height: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 430px;
  padding: 50px 36px 36px 36px;
  margin-top: -10%;

  svg {
    color: #90c53c;
  }
`

const Link = styled.a`
  color: #90c53c;
  text-decoration: none;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled(WarningAmberIcon)`
  width: 120px !important;
  height: 120px !important;

  @media (max-width: ${breakpoints.md}) {
    width: 80px !important;
    height: 80px !important;
  }
`

const ErrorFallback = () => {
  const { t } = useTranslation()
  const supportUrl = 'https://fitforme.com/contact-form/'

  return (
    <ErrorPageLayout>
      <Wrapper>
        <PaperBox elevation={3}>
          <Icon />
          <Title size={1}>{t('error.title')}</Title>
          <Text size={2}>{t('error.description')}</Text>
          <Text size={2}>
            <Trans
              t={t}
              i18nKey={'error.contact-support'}
              components={[<Link key={1} href={supportUrl} target="_blank" />]}
            />
          </Text>
        </PaperBox>
      </Wrapper>
    </ErrorPageLayout>
  )
}

export default ErrorFallback

import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const TextButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.7);
  margin-left: 8px;
`

export interface ToggleKnowSurgeryDateProps {
  isUnknown: boolean
  onToggle: () => void
}

export const ToggleKnowSurgeryDate = ({
  onToggle,
  isUnknown,
}: ToggleKnowSurgeryDateProps) => {
  const { t } = useTranslation()

  const toggleKnowSurgeryDate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onToggle()
  }

  return (
    <TextButton
      onClick={(e: MouseEvent<HTMLButtonElement>) => toggleKnowSurgeryDate(e)}
      type="button"
    >
      {isUnknown
        ? t('user-data.i-know-surgery-date')
        : t('user-data.i-do-not-know-surgery-date')}
    </TextButton>
  )
}

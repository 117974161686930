import React from 'react'
import styled from 'styled-components'
import { formatPrice } from 'helpers/format'
import { useTranslation } from 'react-i18next'

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin: 10px 0 10px 0;
  gap: 20px;
  padding: 0px 0 20px 0;
`

const SubscriptionItem = styled.li`
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 19px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  opacity: ${({ mandatory }) => (mandatory ? 0.5 : 1)};
  pointer-events: ${({ mandatory }) => (mandatory ? 'none' : 'auto')};
  margin: 4px;
`

const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: auto;
  flex: 1;
  opacity: ${({ mandatory }) => (mandatory ? 0.5 : 1)};
`

const ItemDescription = styled.span`
  font-weight: 600;
`

const ItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
`

const BundleProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 20px;
  margin-top: 10px;
`

const Interval = styled.span`
  color: #90c449;
  font-size: 85%;
`

const Quantity = styled.span`
  color: #90c449;
  font-weight: bold;
  font-size: 12px;
`

const intervalMap = {
  '90Dv2': 'cart.interval_90',
  '180D': 'cart.interval_180',
  '90D': 'cart.interval_90',
}

export const ChargebeeSubscriptionItems = ({ items }) => {
  const { t } = useTranslation()

  return (
    <ItemsList>
      {items.map((item, itemIndex) => {
        let currency = item.currency
        let interval = item.interval
        let itemPrice = item.priceWithDiscount || 0
        if (item.products) {
          currency = item.products[0]?.currency
          interval = item.products[0]?.interval

          item.products.forEach(({ priceWithDiscount, quantity }) => {
            const bundleItemPrice = Number(priceWithDiscount)
            const bundleItemQuantity = quantity || 1
            const total = bundleItemPrice * bundleItemQuantity
            itemPrice += total
          })
        }

        return (
          <SubscriptionItem
            key={`cb-subscription-item-${itemIndex}`}
            mandatory={item.mandatory}
          >
            <ItemHeader>
              <ItemName>
                <ItemDescription>
                  <Quantity>{item.quantity} × </Quantity>
                  {item.name}
                </ItemDescription>
                <span>{item.productName}</span>
              </ItemName>
              <span>
                <Price>{formatPrice(itemPrice, currency)}</Price>
                {!!intervalMap[interval] && (
                  <Interval>
                    {' / '}
                    {t(intervalMap[interval])}
                  </Interval>
                )}
              </span>
            </ItemHeader>
            {item.products && (
              <BundleProductsWrapper>
                {item.products.map((product, productIndex) => {
                  return (
                    <ItemName
                      key={`product${productIndex}`}
                      mandatory={product.mandatory}
                    >
                      <ItemDescription>
                        <Quantity>{product.quantity || 1} × </Quantity>
                        {product.name}
                      </ItemDescription>
                    </ItemName>
                  )
                })}
              </BundleProductsWrapper>
            )}
          </SubscriptionItem>
        )
      })}
    </ItemsList>
  )
}

import React from 'react'
import styled from 'styled-components'
import Title from 'components/atoms/title'
import Text from 'components/atoms/text'
import { formatDate } from 'helpers/format'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import { useTranslation } from 'react-i18next'
import { ChargebeeSubscriptionItems } from 'components/molecules/chargebee-subscription-items'
import { PimcoreSubscriptionItems } from 'components/molecules/pimcore-subscription-items'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'

const SubscriptionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Subscription = styled.li`
  padding: 4px;
`

const StartingDate = styled.span`
  color: #90c449;
  font-weight: bold;
`

const Subscriptions = () => {
  const { t } = useTranslation()
  const { subscriptions } = useSubscriptions()
  const { productFlow } = useCheckoutInfo()

  const getTitle = (subscription) => {
    if (subscription?.interval?.includes('SKT')) {
      return t('subscription.starterkit')
    } else if (subscription?.interval?.includes('SGL')) {
      return t('subscription.single')
    }
    return t('subscription.subscription')
  }

  return (
    <SubscriptionsList>
      {subscriptions.map((subscription, subscriptionIndex) => {
        return (
          <Subscription key={`subscriptions-${subscriptionIndex}`}>
            <Title size={4}>{getTitle(subscription)}</Title>
            <Text size={3}>
              {t('subscription.start-date')}:{' '}
              <StartingDate>
                {subscription.startDate
                  ? formatDate(new Date(subscription.startDate))
                  : t('subscription.immediately')}
              </StartingDate>
            </Text>
            {productFlow === 'v1' ? (
              <ChargebeeSubscriptionItems items={subscription.items} />
            ) : (
              <PimcoreSubscriptionItems items={subscription.items} />
            )}
          </Subscription>
        )
      })}
    </SubscriptionsList>
  )
}

export default Subscriptions

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'ffm-ui'
import useRequest from 'hooks/request/useRequest'
import useLanguage from 'hooks/language/useLanguage'
import Text from 'components/atoms/text'
import Title from 'components/atoms/title'
import { isEmail } from 'helpers/helper'
import { AUTH_VIEWS, LinkButton, StyledForm } from '.'

export const BaseForm = ({
  setAuthResponse,
  setView,
  email,
  setEmail,
  password,
  setPassword,
  emailError,
  setEmailError,
  passwordError,
  setPasswordError,
  visible,
}) => {
  const { t } = useTranslation()
  const { lang } = useLanguage()

  const { doRequest: checkIfAccountExists } = useRequest({
    url: '/api/customer/otp/check-email',
    method: 'post',
    body: { email },
    onSuccess: (response) => {
      if (!response?.exist) {
        setEmailError('auth.dont-have-account-yet')
        return false
      }

      setEmailError('')
      return true
    },
  })

  const { doRequest: login, loading: loadingLogin } = useRequest({
    url: '/api/token',
    method: 'post',
    onSuccess: (res) => {
      setAuthResponse(res)
      setView(AUTH_VIEWS.CHOOSE_ACCOUNT)
    },
  })

  const handleLogin = useCallback(async () => {
    if (!isEmail(email)) {
      setEmailError('auth.enter-valid-email')
      return
    }

    if (password === '') {
      setPasswordError('auth.password-required')
      return
    }

    const { exist } = await checkIfAccountExists({ email })

    if (exist) {
      login({ username: email, password, eventUser: 'Checkout' })
    }
  }, [
    login,
    email,
    password,
    checkIfAccountExists,
    setEmailError,
    setPasswordError,
  ])

  return (
    <StyledForm visible={visible}>
      <Title style={{ margin: 0 }} size={3}>
        {t('auth.provide-password')}
      </Title>
      <Input
        required
        fullWidth
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          setEmailError('')
        }}
        type="email"
        name="email"
        label={t('user-data.email')}
        placeholder={t('user-data.email')}
        error={emailError && t(emailError)}
      />
      <Input
        required
        fullWidth
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
          setPasswordError('')
        }}
        type="password"
        label={t('user-data.password')}
        placeholder={t('user-data.password')}
        error={passwordError && t(passwordError)}
      />

      <Button
        fullWidth
        disabled={loadingLogin || !!emailError || !!passwordError}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleLogin()
        }}
        type="submit"
      >
        {t('auth.login.btn')}
      </Button>

      <Text size={2}>
        <LinkButton
          disabled={emailError}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            const isProd = window.location.hostname === 'checkout.fitforme.com'

            const redirectUrl = window.location.href
            const base = isProd
              ? 'https://my.fitforme.com'
              : 'https://my.fitformedev.nl'

            window.location.href = `${base}/recover-password/request?redirectUrl=${encodeURIComponent(
              redirectUrl
            )}&email=${encodeURIComponent(email)}&lang=${lang}`
          }}
        >
          {t('auth.forgot-password')}
        </LinkButton>
      </Text>

      <Text size={2}>
        {t('auth.new-customer')}
        &nbsp;
        <LinkButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setView(AUTH_VIEWS.NEW)
            setEmailError(null)
          }}
        >
          {t('auth.sign-up')}
        </LinkButton>
      </Text>
    </StyledForm>
  )
}

import React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'helpers/style-constants'

const StyledH1 = styled.h1`
  font-size: 30px;
  line-height: 41px;
  font-weight: 600;
  margin: 0;

  @media (max-width: ${breakpoints.md}) {
    font-size: 26px;
    line-height: 25px;
  }
`

const StyledH2 = styled.h2`
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
`

const StyledH3 = styled.h3`
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin: 0;
`

const StyledH4 = styled.h4`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
`

const StyledH5 = styled.h4`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin: 0;
`

const map = {
  1: StyledH1,
  2: StyledH2,
  3: StyledH3,
  4: StyledH4,
  5: StyledH5,
}

const Title = ({ size, children, ...rest }) => {
  const TitleComponent = map[size] || map[1]
  return <TitleComponent {...rest}>{children}</TitleComponent>
}

export default Title

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import { Button } from 'ffm-ui'
import { useNavigate } from 'react-router-dom'

import { isMobile } from 'helpers/helper'
import { HeaderMobile } from 'components/organisms/header-mobile'
import { Header } from 'components/organisms/header'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import Title from 'components/atoms/title'
import Text from 'components/atoms/text'
import { breakpoints } from 'helpers/style-constants'

const Page = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
`

const PaperBox = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 50px 36px;
  margin: 0 auto;
  max-width: 500px;
  width: calc(100% - 80px);
  gap: 20px;
  justify-content: center;
  align-items: center;

  text-align: center;

  & a {
    display: flex !important;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 32px 20px;
  }
`

const SessionExpired = () => {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const { isSessionExpired } = useCheckoutInfo()

  useEffect(() => {
    if (!isSessionExpired) {
      navigate(`/personal-details${window.location.search}`)
    }
  }, [isSessionExpired, navigate])

  return (
    <Page>
      {!isMobile() ? <Header /> : <HeaderMobile />}
      <Wrapper>
        <PaperBox elevation={3}>
          <Title size={1}>{t('checkout.session-expired.header')}</Title>
          <Text size={2}>{t('checkout.session-expired.body')}</Text>
          <Button
            href="https://fitforme.com/customerservice/"
            target="_blank"
            style={{ color: '#90c53c' }}
            variant="link"
          >
            {t('checkout.session-expired.link')}
          </Button>
        </PaperBox>
      </Wrapper>
    </Page>
  )
}

export default SessionExpired

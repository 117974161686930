import React from 'react'
import styled from 'styled-components'

const StyledSpan1 = styled.span`
  font-size: 24px;
  line-height: 33px;
`

const StyledSpan2 = styled.span`
  font-size: 16px;
  line-height: 24px;
`

const StyledSpan3 = styled.span`
  font-size: 14px;
  line-height: 22px;
`

const StyledSpan4 = styled.span`
  font-size: 12px;
  line-height: 16px;
`

const map = {
  1: StyledSpan1,
  2: StyledSpan2,
  3: StyledSpan3,
  4: StyledSpan4,
}

const Text = (props) => {
  const { size, children } = props
  const TextComponent = map[size] || map[1]
  return <TextComponent {...props}>{children}</TextComponent>
}

export default Text

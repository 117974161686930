import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import useCustomer from 'hooks/customer/useCustomer'

import Main from 'components/layouts/main'
import PersonalDetails from 'components/pages/personal-details'
import ShippingDetails from 'components/pages/shipping-details'
import CreateSubscription from 'components/pages/create-subscription'
import ErrorFallback from 'components/pages/error'
import ThankYou from 'components/pages/thank-you'

const ProtectedRoute = () => {
  const { customerCBId } = useCustomer()

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Main />,
      errorElement: <ErrorFallback />,
      children: [
        {
          path: '/shipping-details',
          element: <ShippingDetails />,
        },
        {
          path: '/personal-details',
          element: <PersonalDetails />,
        },
        {
          path: '/create-subscription',
          element: <CreateSubscription />,
        },
        {
          path: '*',
          element: customerCBId ? <ShippingDetails /> : <PersonalDetails />,
        },
      ],
    },
    {
      path: '/thanks',
      element: <ThankYou />,
    },
    {
      path: '/error',
      element: <ErrorFallback />,
    },
  ])

  return <RouterProvider router={router} />
}

export default ProtectedRoute

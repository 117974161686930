// adyen dropin logic were implemented using this manual https://docs.adyen.com/online-payments/web-drop-in/integrated-before-5-0-0
import { useEffect, useRef, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AdyenCheckout from '@adyen/adyen-web'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import useRequest, {
  getErrorMessage,
  useRequestErrorHandler,
} from 'hooks/request/useRequest'
import { token } from 'hooks/request/access_token'
import useNotify from 'hooks/notify/useNotify'
import useCustomer from 'hooks/customer/useCustomer'
import { formatDateForAPI } from 'helpers/format'
import { getChargebeeSubscriptions, getSuccessMessage } from './utils'
import request from 'hooks/request/request'
import { sendEvent } from 'helpers/analytics'
import { minimumExpiryDate } from './utils'
import { trackError } from 'helpers/trackError'
import i18n from 'locales/i18n'

const cardNames = {
  visa: 'Visa',
  sepadirectdebit: 'SEPA',
  ideal: 'Ideal',
  mc: 'Mastercard',
  bcmc: 'Bancontact',
  twint: 'twint',
  EPS: 'EPS',
  klarna: 'Klarna',
  klarna_paynow: 'Klarna paynow',
  klarna_account: 'Klarna pay over time',
}

const klarnaMethods = new Set(['klarna', 'klarna_paynow', 'klarna_account'])

export const useAdyen = ({ redirectUrl }) => {
  const [adyen, setAdyen] = useState()
  const [loading, setLoading] = useState()
  const [paypalLoading, setPaypalLoading] = useState(false)
  const [isRefused, setIsRefused] = useState(false)
  const { t } = useTranslation()
  const { billingAddress, personalDetails, shippingAddress, customerCBId } =
    useCustomer()
  const { totalPrice, validatedPromocode, subscriptions } = useSubscriptions()
  const { openNotification } = useNotify()
  const { requestErrorHandler } = useRequestErrorHandler()

  const {
    store,
    sessionId,
    utmMedium,
    utmSource,
    currency,
    session,
    adyenMethods,
    productFlow,
  } = useCheckoutInfo()

  const { doRequest: payWithPaypal } = useRequest({
    url: 'paypal-multiparty/checkout-payment',
    method: 'post',
    onError: (error) => {
      trackError(
        `Paypal payment failed: ${getErrorMessage(error)}`,
        'Paypal payment method'
      )

      setPaypalLoading(false)
      requestErrorHandler(error)
    },
  })

  const { doRequest: createChargebeeToken } = useRequest({
    url: `checkout/adyen-payment?token=${token}`,
    method: 'post',
    onError: (error, requestBody) => {
      trackError(
        `Failed to create token: ${getErrorMessage(error)}`,
        `${
          cardNames[requestBody?.paymentMethod?.type]
            ? `${cardNames[requestBody?.paymentMethod?.type]} payment method`
            : ''
        }`
      )
      requestErrorHandler(error)
    },
  })

  const { doRequest: createPimcoreSubscription } = useRequest({
    url: `/api/checkout/${sessionId}/subscribe?token=${token}`,
    method: 'post',
    onError: (error, requestBody) => {
      trackError(
        `Failed to create token: ${getErrorMessage(error)}`,
        `${
          cardNames[requestBody?.paymentMethod?.type]
            ? `${cardNames[requestBody?.paymentMethod?.type]} payment method`
            : ''
        }`
      )

      requestErrorHandler(error)
    },
  })

  const paymentContainer = useRef(null)

  const showFinalResult = useCallback(
    (result, dropin) => {
      setLoading(false)
      if (result.resultCode === 'Authorised') {
        dropin?.setStatus('success')
        openNotification({
          type: 'success',
          message: t(getSuccessMessage(subscriptions)),
        })

        window.location.href = redirectUrl
        return
      } else {
        dropin?.setStatus('error')

        trackError(`Dropin final result: ${result.resultCode}`)

        openNotification({
          type: 'error',
          message: t('payment.error'),
        })

        console.error(
          'payment not successful: ',
          JSON.stringify(result, null, '\t')
        )
      }
    },
    [openNotification, redirectUrl, setLoading, t, subscriptions]
  )

  const customer = useMemo(() => {
    return {
      email: personalDetails?.email,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      cf_surgery_date: formatDateForAPI(personalDetails?.surgeryDate),
      cf_surgery_type_v2: personalDetails?.surgeryType,
      cf_clinic_v2: personalDetails?.clinic,
      cf_surgeon_name: personalDetails?.surgeonName,
      cf_surgery_date_unknown: personalDetails?.surgeryDateUnknown,
      cf_date_of_birth: formatDateForAPI(personalDetails?.dateOfBirth),
      phone: personalDetails?.phone?.replace(/\s/g, ''),
      cs_marketing: personalDetails?.marketingConsent,
    }
  }, [personalDetails])

  const cbSubscriptions = useMemo(() => {
    return getChargebeeSubscriptions({
      subscriptions,
      customer,
      billingAddress,
      shippingAddress,
      utmMedium,
      utmSource,
      validatedPromocode,
      customerCBId,
      comment: session?.data?.comment,
      store,
    })
  }, [
    subscriptions,
    customer,
    billingAddress,
    shippingAddress,
    session,
    customerCBId,
    store,
    utmMedium,
    utmSource,
    validatedPromocode,
  ])

  const onSubmit = useCallback(
    async (state, dropin) => {
      if (!state.isValid) {
        return
      }

      if (!subscriptions.length) {
        return
      }
      sendEvent('add_payment_info')

      setLoading(true)
      const paymentMethod = state.data.paymentMethod
      const browserInfo = state.data.browserInfo || {}

      let value = 0

      if (klarnaMethods.has(paymentMethod?.type)) {
        value = totalPrice
      } else if (paymentMethod?.type === 'ideal') {
        value = 1
      } else {
        value = 0
      }

      const tokenPayload = {
        paymentMethod,
        amount: { currency, value },
        subscriptions: cbSubscriptions.map((item) => ({
          ...item,
          autoCollection: 'off',
        })),
        returnUrl: window.location.href,
        sessionId,
        customerId: customerCBId || null,
        browserInfo,
        channel: 'Web',
        origin: window.location.origin,
        countryCode: store.countryCode,
      }

      if (klarnaMethods.has(paymentMethod?.type)) {
        const lineItems = cbSubscriptions
          .map((item) => JSON.parse(item.metaData))
          .map((item) => item.items)
          .flatMap((el) => el)
          .map((item) => {
            if (!item.bundle) {
              return {
                id: item.chargeBeeId,
                quantity: item.quantity,
                amountExcludingTax: Number(item.priceWithDiscount) * 100,
                taxPercentage: 0,
                description: item.productName,
                amountIncludingTax: Number(item.priceWithDiscount) * 100,
              }
            } else {
              return item.products.map((el) => {
                return {
                  id: el.chargeBeeId,
                  quantity: el.quantity,
                  amountExcludingTax: Number(el.priceWithDiscount) * 100,
                  taxPercentage: 0,
                  description: el.productName,
                  amountIncludingTax: Number(el.priceWithDiscount) * 100,
                }
              })
            }
          })
          .flat(1)

        Object.assign(tokenPayload, {
          lineItems,
        })
      }
      let createTokenRespone = null
      if (productFlow === 'v1') {
        createTokenRespone = await createChargebeeToken(tokenPayload)
      } else {
        createTokenRespone = await createPimcoreSubscription({
          ...tokenPayload,
          type: 'adyen',
        })
      }

      const { result, id } = createTokenRespone

      sessionStorage.setItem('paymentId', id)

      setLoading(false)

      if (result) {
        if (result.resultCode === 'Refused') {
          setIsRefused(true)
        }

        if (result.action) {
          dropin.handleAction(result.action)
        } else {
          showFinalResult(result, dropin)
        }
      } else {
        dropin.setStatus('error', { message: t('payment.error') })

        trackError(
          'Failed to create token',
          `${paymentMethod?.type} payment method`
        )
      }
    },
    [
      createPimcoreSubscription,
      productFlow,
      currency,
      t,
      sessionId,
      createChargebeeToken,
      setLoading,
      customerCBId,
      subscriptions,
      store,
      showFinalResult,
      cbSubscriptions,
      totalPrice,
    ]
  )

  const onAdditionalDetails = useCallback(
    async (state, dropin) => {
      const storedPaymentId = sessionStorage.getItem('paymentId')

      request
        .post(
          `checkout/adyen-payment/${storedPaymentId}/details?token=${token}`,
          state.data
        )
        .then((response) => {
          if (response?.data?.action) {
            dropin.handleAction(response.data.action)
          } else {
            showFinalResult(response.data, dropin)
          }
        })
        .catch((error) => {
          throw Error(error)
        })
    },
    [showFinalResult]
  )

  const onError = useCallback(
    (error) => {
      setLoading(false)
      openNotification({ type: 'error', message: t('payment.error') })
      console.error('dropin error: ', JSON.stringify(error, null, '\t'))
      trackError(
        'Dropin error occured',
        `${
          cardNames[error.type] ? `${cardNames[error.type]} payment method` : ''
        } `
      )
    },
    [openNotification, setLoading, t]
  )

  const onResetForm = useCallback(() => {
    adyen.remove(adyen.components[0])
    setAdyen(null)
  }, [adyen])

  const checkoutOptions = useMemo(() => {
    const options = {
      locale: i18n.language,
      paymentMethodsResponse: adyenMethods,
      clientKey: store.adyenKey,
      environment: store.adyenEnv,
      onSubmit,
      onAdditionalDetails,
      onError,
      setStatusAutomatically: true,
      paymentMethodsConfiguration: {
        card: {
          setStatusAutomatically: true,
          hasHolderName: true,
          holderNameRequired: true,
          minimumExpiryDate,
        },
      },
      amount: { value: totalPrice, currency },
    }

    return options
  }, [
    adyenMethods,
    onSubmit,
    onAdditionalDetails,
    onError,
    store,
    totalPrice,
    currency,
  ])

  useEffect(() => {
    if (!store?.adyenEnv || isNaN(totalPrice) || !adyenMethods) {
      if (adyen && isNaN(totalPrice)) {
        onResetForm()
      }

      return
    }

    if (!adyen) {
      const checkout = new AdyenCheckout(checkoutOptions)

      checkout
        .create('dropin', {
          amount: totalPrice,
          currency,
          openFirstPaymentMethod: false,
        })
        .mount(paymentContainer.current)

      setAdyen(checkout)
    } else {
      adyen.setOptions(checkoutOptions)
    }
  }, [
    store,
    totalPrice,
    currency,
    adyen,
    checkoutOptions,
    adyenMethods,
    onResetForm,
  ])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.has('redirectResult')) {
      const redirectResult = urlParams.get('redirectResult')
      const storedPaymentId = sessionStorage.getItem('paymentId')
      request
        .post(
          `checkout/adyen-payment/${storedPaymentId}/details?token=${token}`,
          {
            details: {
              redirectResult: redirectResult,
            },
          }
        )
        .then((response) => {
          if (response.data.action) {
            adyen?.handleAction(response.data.action)
          } else {
            showFinalResult(response.data, adyen)
          }
        })

      urlParams.delete('redirectResult')
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlParams.toString()}`
      )
    }
  }, [adyen, showFinalResult])

  const handleClickPaypal = useCallback(async () => {
    setPaypalLoading(true)
    sendEvent('add_payment_info')

    let result = null

    if (productFlow === 'v1') {
      result = await payWithPaypal({
        session: sessionId,
        successUrl: redirectUrl,
        errorUrl: window.location.href,
        cancelUrl: window.location.href,
        subscriptions: cbSubscriptions,
        customer: customerCBId || null,
      })
    } else {
      result = await createPimcoreSubscription({
        successUrl: redirectUrl,
        errorUrl: window.location.href,
        cancelUrl: window.location.href,
        type: 'paypal',
      })
    }

    const resultUrl = result?.redirectUrl || result?.url
    if (resultUrl) {
      window.location.href = resultUrl
    } else {
      setPaypalLoading(false)
    }
  }, [
    payWithPaypal,
    redirectUrl,
    sessionId,
    cbSubscriptions,
    customerCBId,
    productFlow,
    createPimcoreSubscription,
  ])

  useEffect(() => {
    if (isRefused) {
      const reset = setTimeout(() => {
        onResetForm()
        setIsRefused(false)
      }, 3000)

      return () => clearTimeout(reset)
    }
  }, [isRefused, onResetForm])

  return {
    adyen,
    paymentContainer,
    loading,
    paypalLoading,
    handleClickPaypal,
    isRefused,
    onResetForm,
  }
}

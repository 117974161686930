import React from 'react'
import styled from 'styled-components'

const ListElement = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 12px;
  font-style: italic;
  margin: 0;

  li {
    margin: 5px 0;
  }
`

const AddressTooltipContent = (item, country) => {
  return (
    <ListElement>
      <li>
        <b>First Name</b>: {item.first_name || '-'}
      </li>
      <li>
        <b>Last Name</b>: {item.last_name || '-'}
      </li>
      <li>
        <b>Phone</b>: {item.phone || '-'}
      </li>
      <li>
        <b>Address 1</b>: {item.line1 || '-'}
      </li>
      <li>
        <b>Address 2</b>: {item.line2 || '-'}
      </li>
      <li>
        <b>Address 3</b>: {item.line3 || '-'}
      </li>
      <li>
        <b>Country</b>: {country?.label || '-'}
      </li>
      <li>
        <b>City</b>: {item.city || '-'}
      </li>
      <li>
        <b>State</b>: {item.state || '-'}
      </li>
      <li>
        <b>Zip</b>: {item.zip || '-'}
      </li>
    </ListElement>
  )
}

export default AddressTooltipContent

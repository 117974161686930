import { compose } from 'ramda'
import { lighten, desaturate } from 'polished'
import { createTheme } from '@mui/material/styles'

const getDarkTone = compose(lighten(0.05), desaturate(0.01))
const getLightTone = compose(lighten(0.1), desaturate(0.01))

const theme = createTheme({
  buttonRoot: {
    height: 52,
  },
  palette: {
    primary: {
      light: getLightTone('#90C53C'),
      dark: getDarkTone('#90C53C'),
      main: '#90C53C',
      contrastText: '#ffffff',
    },
    secondary: {
      light: getLightTone('#518EF8'),
      dark: getDarkTone('#518EF8'),
      main: '#518EF8',
      contrastText: '#ffffff',
    },
    gray: {
      light: getLightTone('#C0C0C0'),
      dark: getDarkTone('#C0C0C0'),
      main: '#C0C0C0',
      contrastText: '#ffffff',
    },
    dark: {
      light: getLightTone('#3D4136'),
      dark: getDarkTone('#3D4136'),
      main: '#3D4136',
      contrastText: '#ffffff',
    },
    error: {
      light: getLightTone('#f14336'),
      dark: getDarkTone('#f14336'),
      main: '#f14336',
      contrastText: '#ffffff',
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 52,
          fontSize: 16,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: 52,
          fontSize: 16,
          marginTop: 32,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 52,
          fontSize: 16,
          padding: 16,
          background: '#ffffff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(0px, -32px)',
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
})

export default theme

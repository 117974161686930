import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'ffm-ui'
import useRequest from 'hooks/request/useRequest'
import useNotify from 'hooks/notify/useNotify'
import Text from 'components/atoms/text'
import Title from 'components/atoms/title'
import { isEmail } from 'helpers/helper'
import { AUTH_VIEWS, LinkButton, StyledForm } from './index'
import useLanguage from 'hooks/language/useLanguage'

export const FirstOTPForm = ({
  setAuthResponse,
  setView,
  email,
  setEmail,
  visible,
}) => {
  const { t } = useTranslation()
  const { openNotification } = useNotify()
  const [emailError, setEmailError] = useState('')
  const [otpCodeError, setOtpCodeError] = useState('')
  const [otpCode, setOtpCode] = useState('')
  const { lang } = useLanguage()
  const [codeFetched, setCodeFetched] = useState(false)

  const { doRequest: getOtpCode, loading: getOtpLoading } = useRequest({
    url: `/api/customer/otp/send?language=${lang}`,
    method: 'post',
    onSuccess: () => {
      openNotification({
        type: 'success',
        messageKey: t('success.code-sent'),
      })
    },
  })

  const { doRequest: doLoginOtp, loading: loadingLoginOtp } = useRequest({
    url: '/api/customer/otp/auth',
    method: 'post',
    onSuccess: (res) => {
      setAuthResponse(res)
      setView(AUTH_VIEWS.CHOOSE_ACCOUNT)
    },
    onError: () =>
      openNotification({
        type: 'error',
        messageKey: t('error.otp-is-not-valid'),
      }),
  })

  const handleOtpLogin = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (!isEmail(email)) {
        setEmailError('auth.enter-valid-email')
      }

      if (otpCode === '') {
        setOtpCodeError(t('auth.otp-code-required'))
      }

      if (isEmail(email) && otpCode !== '') {
        doLoginOtp({ password: otpCode, email, eventUser: 'Checkout' })
      }
    },
    [doLoginOtp, email, otpCode, t]
  )

  useEffect(() => {
    if (!codeFetched) {
      setCodeFetched(true)

      getOtpCode({
        email,
        url: window.location.href,
        flow: 'otp_flow_checkout',
      })
    }
  }, [codeFetched, email, getOtpCode])

  return (
    <StyledForm visible={visible}>
      <Title style={{ margin: 0 }} size={3}>
        {t('auth.we-see-that-you-customer')}
      </Title>
      <Input
        required
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        label={t('user-data.email')}
        placeholder={t('user-data.email')}
        error={emailError && emailError}
      />
      <Input
        required
        fullWidth
        value={otpCode}
        label={t('auth.otp-code')}
        placeholder={t('auth.otp-code')}
        onChange={(e) => {
          const digits = e?.target?.value?.replace(/\D/g, '')
          if (digits?.length <= 6) {
            setOtpCode(digits)
            setOtpCodeError('')
          }
        }}
        error={otpCodeError && t(otpCodeError)}
      />
      <Text size={2} style={{ marginBottom: '-16px' }}>
        <LinkButton
          disabled={getOtpLoading}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setCodeFetched(false)
          }}
        >
          {t('auth.resend')}
        </LinkButton>
      </Text>
      <Button
        fullWidth
        disabled={loadingLoginOtp || !!emailError || !!otpCodeError}
        onClick={handleOtpLogin}
        type="submit"
      >
        {t('auth.sign-in')}
      </Button>

      <Text size={2}>
        {t('auth.new-customer')}
        &nbsp;
        <LinkButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setView(AUTH_VIEWS.NEW)
            setEmailError(null)
          }}
        >
          {t('auth.sign-up')}
        </LinkButton>
      </Text>
    </StyledForm>
  )
}

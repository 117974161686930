import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Input,
  Button,
  Autocomplete,
  PhoneInput,
  getAddressValidationSchema,
  ZipInput,
} from 'ffm-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import Checkbox from 'components/atoms/checkbox'
import Title from 'components/atoms/title'
import useRequest from 'hooks/request/useRequest'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { token } from 'hooks/request/access_token'
import * as Yup from 'yup'
import Grid from '@mui/material/Grid'
import useCustomer from 'hooks/customer/useCustomer'
import { sendEvent } from 'helpers/analytics'
import { breakpoints } from 'helpers/style-constants'
import AddressBox from 'components/organisms/address-box'

const ButtonWrapper = styled.div`
  width: 230px;
  margin: 40px 0 30px 0;

  @media (max-width: ${breakpoints.md}) {
    margin: 20px 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`

const Link = styled.a`
  color: #90c53c;
  font-weight: bold;
`

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 30px 0;
`

const shippingCountryMap = { CHDE: 'CH', BENL: 'BE', BEFR: 'BE', CHFR: 'CH' }

const fallbackTerms = 'https://fitforme.com/int/terms-and-conditions/'
const fallbackPrivacy = 'https://fitforme.com/int/privacy-and-cookie-statement/'

const getValidationSchema = (store, shippingIsBilling) => {
  const schema = getAddressValidationSchema({ store })

  if (shippingIsBilling) {
    return Yup.object({ shippingAddress: schema })
  }

  return Yup.object({ shippingAddress: schema, billingAddress: schema })
}

const ShippingDetails = () => {
  const {
    billingAddress,
    setBillingAddress,
    shippingAddress,
    personalDetails,
    setPersonalDetails,
    setShippingAddress,
    shippingIsBilling,
    setShippingIsBilling,
    agreeWithTerms,
    setAgreeWithTerms,
    customerCBId,
  } = useCustomer()
  const { setCompletedTabs, setIsDirty } = useOutletContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [newLocation, setNewLocation] = useState(null)
  const { store, updateSession, sessionValuesHandled, sessionUpdating } =
    useCheckoutInfo()
  const [countries, setCountries] = useState([])
  const [initialValuesHandled, setInitialValuesHandled] = useState(false)
  const [initialShippingIsBillingHandled, setInitialShippingIsBillingHandled] =
    useState(false)
  const showOnlyShippingAddress = shippingIsBilling || customerCBId
  const termsUrl = store.termsUrl || fallbackTerms
  const privacyPolicyUrl = store.privacyPolicyUrl || fallbackPrivacy

  const {
    doRequest: fetchCountries,
    isFetched,
    loading,
  } = useRequest({
    url: `/checkout/countries?storeId=${store?.id}&token=${token}`,
    method: 'get',
    onSuccess: (res) =>
      setCountries(res.map(({ code, name }) => ({ value: code, label: name }))),
  })

  useEffect(() => {
    if (store.id && !loading && !isFetched) {
      fetchCountries()
    }
  }, [store, countries.length, fetchCountries, isFetched, loading])

  const onSubmit = useCallback(
    async (values) => {
      sendEvent('add_shipping_info')
      const customerShippingAddress = {
        ...values.shippingAddress,
        phone: values.shippingAddress.phone?.replace(/\s/g, ''),
      }
      const customerBillingAddress = shippingIsBilling
        ? { ...customerShippingAddress }
        : {
            ...values.billingAddress,
            phone: values.billingAddress.phone?.replace(/\s/g, ''),
          }

      setShippingAddress(customerShippingAddress)
      setBillingAddress(customerBillingAddress)
      const updateResult = await updateSession({
        customerShippingAddress,
        customerBillingAddress,
      })

      if (updateResult?.status !== 204) return

      setCompletedTabs((cur) => [...cur, '/shipping-details'])

      setNewLocation('/create-subscription' + window.location.search)
    },
    [
      setBillingAddress,
      setCompletedTabs,
      setShippingAddress,
      shippingIsBilling,
      updateSession,
    ]
  )

  useEffect(() => {
    if (newLocation !== null) navigate(newLocation)
  }, [newLocation, navigate])

  useEffect(() => {
    // try to prefill fields with values from personal details
    setInitialValuesHandled(false)
  }, [
    personalDetails?.firstName,
    personalDetails?.lastName,
    personalDetails?.phone,
  ])

  const handleSelectAddress = (item) => {
    setValue('shippingAddress', {
      firstName: item?.first_name,
      lastName: item?.last_name,
      phone: item?.phone,
      line1: item?.line1,
      line2: item?.line2,
      line3: item?.line3,
      city: item?.city,
      zip: item?.zip,
      country: item?.country,
      state: item?.state,
    })
  }

  const handleInitialShippingIsBilling = useCallback(() => {
    const keys = Object.keys(shippingAddress)
    for (let i = 0; i < keys.length; i++) {
      if (shippingAddress[keys[i]] !== billingAddress[keys[i]]) {
        setShippingIsBilling(false)
        return
      }
    }
    setShippingIsBilling(true)
  }, [billingAddress, setShippingIsBilling, shippingAddress])

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(getValidationSchema(store, showOnlyShippingAddress)),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    reValidateMode: 'onSubmit',
    defaultValues: {
      billingAddress: {
        firstName:
          billingAddress?.firstName || personalDetails?.firstName || '',
        lastName: billingAddress?.lastName || personalDetails?.lastName || '',
        phone: billingAddress?.phone || personalDetails?.phone || '',
        line1: billingAddress?.line1 || '',
        line2: billingAddress?.line2 || '',
        line3: billingAddress?.line3 || '',
        city: billingAddress?.city || '',
        zip: billingAddress?.zip || '',
        country:
          billingAddress?.country ||
          shippingCountryMap[store.countryCode] ||
          '',
        state: billingAddress?.state || '',
      },
      shippingAddress: {
        firstName:
          shippingAddress?.firstName || personalDetails?.firstName || '',
        lastName: shippingAddress?.lastName || personalDetails?.lastName || '',
        phone: shippingAddress?.phone || personalDetails?.phone || '',
        line1: shippingAddress?.line1 || '',
        line2: shippingAddress?.line2 || '',
        line3: shippingAddress?.line3 || '',
        city: shippingAddress?.city || '',
        zip: shippingAddress?.zip || '',
        country:
          shippingAddress?.country ||
          shippingCountryMap[store.countryCode] ||
          '',
        state: shippingAddress?.state || '',
      },
    },
  })

  useEffect(() => {
    if (!getValues()?.shippingAddress?.country && countries.length === 1) {
      setValue('shippingAddress.country', countries[0].value)
      setValue('billingAddress.country', countries[0].value)
    }
  }, [countries, getValues, setValue])

  useEffect(() => {
    if (!sessionValuesHandled) return
    if (initialValuesHandled) return
    setInitialValuesHandled(true)

    reset({
      billingAddress: {
        firstName:
          billingAddress?.firstName || personalDetails?.firstName || '',
        lastName: billingAddress?.lastName || personalDetails?.lastName || '',
        phone: billingAddress?.phone || personalDetails?.phone || '',
        line1: billingAddress?.line1 || '',
        line2: billingAddress?.line2 || '',
        line3: billingAddress?.line3 || '',
        city: billingAddress?.city || '',
        zip: billingAddress?.zip || '',
        country:
          billingAddress?.country ||
          shippingCountryMap[store.countryCode] ||
          '',
        state: billingAddress?.state || '',
      },
      shippingAddress: {
        firstName:
          shippingAddress?.firstName || personalDetails?.firstName || '',
        lastName: shippingAddress?.lastName || personalDetails?.lastName || '',
        phone: shippingAddress?.phone || personalDetails?.phone || '',
        line1: shippingAddress?.line1 || '',
        line2: shippingAddress?.line2 || '',
        line3: shippingAddress?.line3 || '',
        city: shippingAddress?.city || '',
        zip: shippingAddress?.zip || '',
        country:
          shippingAddress?.country ||
          shippingCountryMap[store.countryCode] ||
          '',
        state: shippingAddress?.state || '',
      },
    })
  }, [
    reset,
    shippingAddress,
    billingAddress,
    initialValuesHandled,
    personalDetails,
    sessionValuesHandled,
    store,
  ])

  useEffect(() => {
    if (shippingIsBilling) {
      setBillingAddress(shippingAddress)
    }
  }, [setBillingAddress, shippingAddress, shippingIsBilling])

  useEffect(() => {
    if (!initialShippingIsBillingHandled) {
      handleInitialShippingIsBilling()
      setInitialShippingIsBillingHandled(true)
    }
  }, [initialShippingIsBillingHandled, handleInitialShippingIsBilling])

  useEffect(() => {
    setIsDirty(isDirty)
  }, [isDirty, setIsDirty])

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={(e) => setError(e.target.name, null)}
      >
        <Title size={2}>{t('user-data.shipping-address')}</Title>
        <AddressBox
          countries={countries}
          handleSelectAddress={handleSelectAddress}
        />

        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.first-name')} *`}
                  error={t(errors?.shippingAddress?.firstName?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.last-name')} *`}
                  error={t(errors?.shippingAddress?.lastName?.message)}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.country"
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                initialValuesHandled && (
                  <Autocomplete
                    {...field}
                    ref={null}
                    disablePortal
                    disabled={countries.length === 1}
                    label={`${t('user-data.country')} *`}
                    value={
                      countries.find(
                        (item) =>
                          item.value === getValues()?.shippingAddress?.country
                      )?.label || null
                    }
                    options={countries}
                    onChange={(event, newValue) => {
                      field.onChange(event)
                      setError('shippingAddress.country', null)
                      setValue(
                        'shippingAddress.country',
                        newValue ? newValue.value : ''
                      )
                    }}
                    error={t(errors?.shippingAddress?.country?.message)}
                  />
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.line1"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.address')} *`}
                  error={t(errors?.shippingAddress?.line1?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.line2"
              control={control}
              rules={{ required: store.customerAddressLine2Required }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.line2')} ${
                    store.customerAddressLine2Required ? ` *` : ''
                  }`}
                  error={t(errors?.shippingAddress?.line2?.message)}
                />
              )}
            />
          </Grid>
          {store.customerAddressLine3Enabled && (
            <Grid item xs={12} md={6}>
              <Controller
                name="shippingAddress.line3"
                control={control}
                rules={{ required: store.customerAddressLine3Required }}
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={null}
                    fullWidth
                    label={`${t('user-data.line3')} ${
                      store.customerAddressLine3Required ? ` *` : ''
                    }`}
                    error={t(errors?.shippingAddress?.line3?.message)}
                    inputProps={{
                      ...field.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.city')} *`}
                  error={t(errors?.shippingAddress?.city?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="shippingAddress.zip"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ZipInput
                  {...field}
                  ref={null}
                  fullWidth
                  name="shippingAddress.zip"
                  label={`${t('user-data.zip')} *`}
                  error={t(errors?.shippingAddress?.zip?.message)}
                  country={getValues()?.shippingAddress?.country}
                />
              )}
            />
          </Grid>
          {store.customerStateEnabled && (
            <Grid item xs={12} md={6}>
              <Controller
                name="shippingAddress.state"
                control={control}
                rules={{ required: store.customerStateRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={null}
                    fullWidth
                    label={`${t('user-data.state')} ${
                      store.customerStateRequired ? ' *' : ''
                    }`}
                    error={t(errors?.shippingAddress?.state?.message)}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12} md={6} style={{ marginTop: '20px' }}>
            <Controller
              name="shippingAddress.phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  ref={null}
                  label={t('user-data.phone')}
                  error={t(errors?.shippingAddress?.phone?.message)}
                  defaultCountry={store?.countryCode}
                />
              )}
            />
          </Grid>
        </Grid>

        {showOnlyShippingAddress ? null : (
          <>
            <Title size={2} style={{ marginTop: '48px' }}>
              {t('user-data.billing-address')}
            </Title>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.firstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.first-name')} *`}
                      error={t(errors?.billingAddress?.firstName?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.last-name')} *`}
                      error={t(errors?.billingAddress?.lastName?.message)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.country"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    initialValuesHandled && (
                      <Autocomplete
                        {...field}
                        ref={null}
                        disablePortal
                        disabled={countries.length === 1}
                        label={`${t('user-data.country')} *`}
                        value={
                          countries.find(
                            (item) =>
                              item.value ===
                              getValues()?.billingAddress?.country
                          )?.label || null
                        }
                        options={countries}
                        onChange={(event, newValue) => {
                          field.onChange(event)
                          setError('billingAddress.country', null)
                          setValue(
                            'billingAddress.country',
                            newValue ? newValue.value : ''
                          )
                        }}
                        error={t(errors?.billingAddress?.country?.message)}
                      />
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.line1"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.line1')} *`}
                      error={t(errors?.billingAddress?.line1?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.line2"
                  control={control}
                  rules={{ required: store.customerAddressLine2Required }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.line2')} ${
                        store.customerAddressLine2Required ? ` *` : ''
                      }`}
                      error={t(errors?.billingAddress?.line2?.message)}
                    />
                  )}
                />
              </Grid>
              {store.customerAddressLine3Enabled && (
                <Grid item xs={12} md={6}>
                  <Controller
                    name="billingAddress.line3"
                    control={control}
                    rules={{ required: store.customerAddressLine3Required }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        ref={null}
                        fullWidth
                        label={`${t('user-data.line3')} ${
                          store.customerAddressLine3Required ? ` *` : ''
                        }`}
                        error={t(errors?.billingAddress?.line3?.message)}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.city')} *`}
                      error={t(errors?.billingAddress?.city?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.zip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ZipInput
                      {...field}
                      ref={null}
                      fullWidth
                      label={`${t('user-data.zip')} *`}
                      error={t(errors?.billingAddress?.zip?.message)}
                      country={getValues()?.billingAddress?.country}
                    />
                  )}
                />
              </Grid>
              {store.customerStateEnabled && (
                <Grid item xs={12} md={6}>
                  <Controller
                    name="billingAddress.state"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        ref={null}
                        fullWidth
                        label={t('user-data.state')}
                        error={t(errors?.billingAddress?.state?.message)}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} mt={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="billingAddress.phone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      ref={null}
                      fullWidth
                      label={t('user-data.phone')}
                      error={t(errors?.billingAddress?.phone?.message)}
                      defaultCountry={store?.countryCode}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Checkboxes>
          {!customerCBId && (
            <Checkbox
              isChecked={shippingIsBilling}
              setIsChecked={setShippingIsBilling}
              label={t('user-data.same-as-shipping')}
            />
          )}

          <Checkbox
            isChecked={agreeWithTerms}
            setIsChecked={setAgreeWithTerms}
            label={
              <span style={{ cursor: 'initial' }}>
                <Trans i18nKey="shipping-details.agree-with-terms-and-conditions">
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    href={termsUrl}
                  />
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    href={privacyPolicyUrl}
                  />
                </Trans>
              </span>
            }
          />

          <Checkbox
            isChecked={personalDetails.marketingConsent}
            setIsChecked={() =>
              setPersonalDetails((cur) => ({
                ...cur,
                marketingConsent: !cur.marketingConsent,
              }))
            }
            label={t('user-data.marketing')}
          />
        </Checkboxes>

        <ButtonWrapper>
          <Button type="submit" disabled={!agreeWithTerms || sessionUpdating}>
            {t('user-data.save')}
          </Button>
        </ButtonWrapper>
      </form>
    </>
  )
}

export default ShippingDetails

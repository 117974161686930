import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import {
  Input,
  Button,
  Autocomplete,
  PhoneInput,
  getPersonalDetailsValidationSchema,
  DatePicker,
} from 'ffm-ui'
import { createFilterOptions } from '@mui/material/Autocomplete'
import Title from 'components/atoms/title'
import useRequest from 'hooks/request/useRequest'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { token } from 'hooks/request/access_token'
import { useForm, Controller } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import useNotify from 'hooks/notify/useNotify'
import useCustomer from 'hooks/customer/useCustomer'
import { sendEvent } from 'helpers/analytics'
import useLanguage from 'hooks/language/useLanguage'
import { ToggleKnowSurgeryDate } from './toggle-know-surgery-date'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import { breakpoints } from 'helpers/style-constants'

const filter = createFilterOptions()

const ButtonWrapper = styled.div`
  width: 230px;
  margin: 40px 0 30px 0;

  @media (max-width: ${breakpoints.md}) {
    margin: 20px 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`

const PersonalDetails = () => {
  const { t } = useTranslation()
  const { lang } = useLanguage()
  const navigate = useNavigate()
  const [newLocation, setNewLocation] = useState(null)
  const { setCompletedTabs, setIsDirty } = useOutletContext()
  const { personalDetails, setPersonalDetails } = useCustomer()
  const { store, updateSession, sessionValuesHandled, sessionUpdating } =
    useCheckoutInfo()
  const { openNotification } = useNotify()
  const [surgeryTypes, setSurgeryTypes] = useState([])
  const [clinics, setClinics] = useState([])
  const [initialValuesHandled, setInitialValuesHandled] = useState(false)

  const {
    doRequest: fetchSurgeryTypes,
    isFetched: isFetchedSurgery,
    loading: isLoadingSurgery,
  } = useRequest({
    url: `/checkout/surgery-types?storeId=${store?.id}&token=${token}`,
    method: 'get',
    onSuccess: (res) =>
      setSurgeryTypes(res.map(({ name }) => ({ val: name, label: name }))),
  })

  const {
    doRequest: fetchClinics,
    isFetched: isFetchedClinics,
    loading: isLoadingClinics,
  } = useRequest({
    url: `/checkout/clinics?storeId=${store?.id}&token=${token}`,
    method: 'get',
    onSuccess: (res) => {
      const values = res.map(({ name }) => ({ val: name, label: name }))
      if (personalDetails?.clinic) {
        setClinics([
          ...values,
          { val: personalDetails?.clinic, label: personalDetails?.clinic },
        ])
      } else {
        setClinics(values)
      }
    },
  })

  const { doRequest: addNewClinic } = useRequest({
    url: `/checkout/clinics?storeId=${store?.id}&token=${token}`,
    method: 'post',
    onError: () => {
      openNotification({
        type: 'error',
        message: t('user-data.new.clinic.error'),
      })
    },
  })

  const onSubmit = useCallback(
    async (values) => {
      sendEvent('add_personal_info')
      setPersonalDetails({
        ...values,
        surgeryDateUnknown: personalDetails.surgeryDateUnknown,
      })

      const updateResult = await updateSession({
        customerPersonalInfo: {
          ...values,
          phone: values.phone?.replace(/\s/g, ''),
          surgeryDateUnknown: personalDetails.surgeryDateUnknown,
        },
      })

      if (updateResult?.status !== 204) return

      const isNewClinic =
        !!values.clinic && !clinics.find((item) => item.label === values.clinic)

      if (isNewClinic && store?.customerAllowAddClinic) {
        await addNewClinic({ name: values.clinic })
        setClinics([...clinics, { val: values.clinic, label: values.clinic }])
      }

      setCompletedTabs((cur) => [...cur, '/personal-details'])

      setNewLocation('/shipping-details' + window.location.search)
    },
    [
      addNewClinic,
      clinics,
      setCompletedTabs,
      setPersonalDetails,
      store,
      updateSession,
      personalDetails,
    ]
  )

  useEffect(() => {
    if (newLocation !== null) navigate(newLocation)
  }, [newLocation, navigate])

  useEffect(() => {
    if (store?.id) {
      if (!surgeryTypes.length && !isFetchedSurgery && !isLoadingSurgery) {
        fetchSurgeryTypes()
      }
      if (!clinics.length && !isFetchedClinics && !isLoadingClinics) {
        fetchClinics()
      }
    }
  }, [
    store,
    fetchSurgeryTypes,
    fetchClinics,
    clinics,
    surgeryTypes,
    isFetchedSurgery,
    isLoadingSurgery,
    isFetchedClinics,
    isLoadingClinics,
  ])

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(
      getPersonalDetailsValidationSchema({
        store,
        surgeryDateUnknown: personalDetails?.surgeryDateUnknown,
      })
    ),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    reValidateMode: 'onSubmit',
    defaultValues: {
      firstName: personalDetails?.firstName || '',
      lastName: personalDetails?.lastName || '',
      email: personalDetails?.email || '',
      phone: personalDetails?.phone || '',
      clinic: personalDetails?.clinic,
      surgeryDate: personalDetails?.surgeryDate
        ? new Date(personalDetails?.surgeryDate)
        : null,
      dateOfBirth: personalDetails?.dateOfBirth
        ? new Date(personalDetails?.dateOfBirth)
        : null,
      surgeryType: personalDetails?.surgeryType,
      surgeonName: personalDetails?.surgeonName || '',
      surgeryDateUnknown: personalDetails?.surgeryDateUnknown,
      marketingConsent: personalDetails?.marketingConsent,
    },
  })

  const onToggleKnowDate = () => {
    setPersonalDetails((cur) => ({
      ...cur,
      surgeryDateUnknown: !cur.surgeryDateUnknown,
    }))
    setValue('surgeryDate', null)
  }

  useEffect(() => {
    if (!sessionValuesHandled) return
    if (initialValuesHandled) return
    setInitialValuesHandled(true)
    reset({
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      email: personalDetails?.email,
      phone: personalDetails?.phone,
      clinic: personalDetails?.clinic,
      surgeryDate: personalDetails?.surgeryDate
        ? new Date(personalDetails?.surgeryDate)
        : null,
      dateOfBirth: personalDetails?.dateOfBirth
        ? new Date(personalDetails?.dateOfBirth)
        : null,
      surgeryType: personalDetails?.surgeryType,
      surgeonName: personalDetails?.surgeonName,
      surgeryDateUnknown: personalDetails?.surgeryDateUnknown,
      marketingConsent: personalDetails?.marketingConsent,
    })
  }, [personalDetails, sessionValuesHandled, initialValuesHandled, reset])

  useEffect(() => {
    setIsDirty(isDirty)
  }, [isDirty, setIsDirty])

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={(e) => setError(e.target.name, null)}
      >
        <Title size={2}>{t('checkout.personal-details')}</Title>
        <Grid container columnSpacing={2} rowSpacing={3} mt={2} mb={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.first-name')} *`}
                  error={t(errors.firstName?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.last-name')} *`}
                  error={t(errors.lastName?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={null}
                  fullWidth
                  label={`${t('user-data.email')} *`}
                  error={t(errors.email?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  ref={null}
                  fullWidth
                  label={t('user-data.phone')}
                  error={t(errors.phone?.message)}
                  defaultCountry={store?.countryCode}
                />
              )}
            />
          </Grid>
          {store?.customerClinicEnabled && (
            <Grid item xs={12} md={6}>
              <Controller
                name="clinic"
                control={control}
                rules={{ required: store?.customerClinicRequired }}
                render={({ field }) =>
                  initialValuesHandled && (
                    <Autocomplete
                      {...field}
                      ref={null}
                      disablePortal
                      label={`${t('user-data.clinic')} ${
                        store?.customerClinicRequired ? ' *' : ''
                      }`}
                      options={clinics.map((item) => ({
                        value: item.val,
                        label: item.label,
                      }))}
                      onChange={async (event, newValue) => {
                        field.onChange(event)
                        setError('clinic', null)

                        if (typeof newValue === 'string') {
                          setValue('clinic', newValue)
                        } else if (newValue && newValue.inputValue) {
                          if (store?.customerAllowAddClinic) {
                            try {
                              await addNewClinic({ name: newValue.inputValue })

                              setValue('clinic', newValue.inputValue)

                              setClinics([
                                ...clinics,
                                {
                                  val: newValue.inputValue,
                                  label: newValue.inputValue,
                                },
                              ])
                            } catch {
                              setValue('clinic', '')
                            }
                          }
                        } else {
                          setValue('clinic', newValue?.value || '')
                        }
                      }}
                      error={t(errors.clinic?.message)}
                      freeSolo
                      clearOnBlur
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params)
                        const { inputValue } = params
                        const isExisting = options.some(
                          (option) => inputValue === option.label
                        )

                        if (
                          inputValue !== '' &&
                          !isExisting &&
                          store?.customerAllowAddClinic
                        ) {
                          filtered.push({
                            inputValue,
                            label: `Add "${inputValue}"`,
                          })
                        }

                        return filtered
                      }}
                      handleHomeEndKeys
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option
                        }

                        if (option.inputValue) {
                          return option.inputValue
                        }

                        return option.label
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                      )}
                    />
                  )
                }
              />
            </Grid>
          )}
          {store?.customerSurgeryDateEnabled && (
            <Grid item xs={12} md={6}>
              {personalDetails?.surgeryDateUnknown ? (
                <Input
                  fullWidth
                  label={t('user-data.surgery-date')}
                  value={t('user-data.surgery-date-unknown')}
                  disabled
                />
              ) : (
                <Controller
                  name="surgeryDate"
                  control={control}
                  rules={{ required: store?.customerSurgeryDateRequired }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      ref={null}
                      lang={lang}
                      fullWidth
                      onChange={(newValue) => {
                        setValue('surgeryDate', newValue)
                        setError('surgeryDate', null)
                      }}
                      label={`${t('user-data.surgery-date')} ${
                        store?.customerSurgeryDateRequired ? ' *' : ''
                      }`}
                      error={t(errors.surgeryDate?.message)}
                      minDate={new Date('01/01/2000')}
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 2)
                        )
                      }
                    />
                  )}
                />
              )}

              <ToggleKnowSurgeryDate
                isUnknown={personalDetails?.surgeryDateUnknown}
                onToggle={onToggleKnowDate}
              />
            </Grid>
          )}
          {store?.customerSurgeryTypeEnabled && (
            <Grid item xs={12} md={6}>
              <Controller
                name="surgeryType"
                control={control}
                rules={{ required: store?.customerSurgeryTypeRequired }}
                render={({ field }) =>
                  initialValuesHandled && (
                    <Autocomplete
                      {...field}
                      ref={null}
                      disablePortal
                      label={`${t('user-data.surgery-type')} ${
                        store?.customerSurgeryTypeRequired ? ' *' : ''
                      }`}
                      options={surgeryTypes.map((item) => ({
                        value: item.val,
                        label: item.label,
                      }))}
                      onChange={(event, newValue) => {
                        field.onChange(event)
                        setError('surgeryType', null)
                        setValue(
                          'surgeryType',
                          newValue ? newValue?.value : null
                        )
                      }}
                      error={t(errors.surgeryType?.message)}
                    />
                  )
                }
              />
            </Grid>
          )}
          {store?.customerSurgeonEnabled && (
            <Grid item xs={12} md={6}>
              <Controller
                name="surgeonName"
                control={control}
                rules={{ required: store?.customerSurgeonRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={null}
                    fullWidth
                    label={`${t('user-data.surgeon-name')} ${
                      store?.customerSurgeonRequired ? ' *' : ''
                    }`}
                    error={t(errors.surgeonName?.message)}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  ref={null}
                  fullWidth
                  label={t('user-data.date-of-birth')}
                  lang={lang}
                  minDate={new Date('01/01/1922')}
                  maxDate={new Date()}
                  error={t(errors.dateOfBirth?.message)}
                />
              )}
            />
          </Grid>
        </Grid>

        <ButtonWrapper>
          <Button type="submit" disabled={sessionUpdating}>
            {t('user-data.save')}
          </Button>
        </ButtonWrapper>
      </form>
    </>
  )
}

export default PersonalDetails

import { isBeforeToday } from 'helpers/helper'
import { getAddonsFromSubscription } from 'components/organisms/subscriptions/utils'

export const getChargebeeSubscriptions = ({
  subscriptions,
  customer,
  billingAddress,
  shippingAddress,
  utmMedium,
  utmSource,
  validatedPromocode,
  customerCBId,
  comment,
  store,
}) => {
  const result = []

  if (subscriptions.length) {
    if (store?.countryCode === 'FR') {
      shippingAddress = {
        ...shippingAddress,
        line1: shippingAddress.line2,
        line2: shippingAddress.line1,
      }

      billingAddress = {
        ...billingAddress,
        line1: billingAddress.line2,
        line2: billingAddress.line1,
      }
    }

    subscriptions
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
      .forEach((subscription) => {
        const addons = getAddonsFromSubscription(subscription)
        if (!addons.length) return

        let startDate = subscription.startDate

        if (isBeforeToday(new Date(startDate))) {
          startDate = null
        }

        const newSubscriptionObject = {
          planId: subscription.plan,
          shippingAddress,
          addons,
          cf_utm_medium: utmMedium,
          cf_utm_source: utmSource,
          couponIds: validatedPromocode ? [validatedPromocode] : [],
          startDate: startDate ? new Date(startDate) / 1000 : null,
        }

        if (!customerCBId) {
          newSubscriptionObject.customer = customer
          newSubscriptionObject.billingAddress = billingAddress
        }

        newSubscriptionObject['metaData'] = JSON.stringify(subscription)

        result.push(newSubscriptionObject)
      })
  }

  if (result.length) {
    result[0].comment = comment
  }

  return result
}

export const getSuccessMessage = (subscriptions) => {
  const intervals = subscriptions.map((item) => item.interval)

  if (intervals?.includes('SKT')) {
    return 'subscription.starterkit-created'
  } else if (intervals?.includes('SGL')) {
    return 'success.one-time-subscription-created'
  }

  return 'success.subscription-created'
}

const today = new Date()
const nextMonth = new Date()
nextMonth.setMonth(today.getMonth() + 1)

export const minimumExpiryDate = nextMonth.toLocaleDateString('en-GB', {
  year: 'numeric',
  month: 'numeric',
})

import React from 'react'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { ReactComponent as CheckIcon } from 'images/check.svg'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: ${(props) => (props.checked ? '#90C53C' : 'white')};
  border: 1px solid #90c53c;
  color: white;
  border-radius: 3px;
  transition: all 150ms;
  flex-shrink: 0;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

// TODO: move to FFM-UI
const Checkbox = ({ isChecked, setIsChecked, label, disabled, ...rest }) => (
  <CheckboxContainer
    onClick={() => setIsChecked(!isChecked)}
    disabled={disabled}
    {...rest}
  >
    <HiddenCheckbox
      checked={isChecked}
      onChange={(ev) => setIsChecked(ev.target.checked)}
    />
    <StyledCheckbox checked={isChecked}>
      <CheckIcon />
    </StyledCheckbox>
    {!!label && <Text size={3}>{label}</Text>}
  </CheckboxContainer>
)

export default Checkbox

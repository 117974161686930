import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'ffm-ui'

import Title from 'components/atoms/title'
import Text from 'components/atoms/text'
import { formatPrice } from 'helpers/format'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import useRequest from 'hooks/request/useRequest'
import useNotify from 'hooks/notify/useNotify'
import { token } from 'hooks/request/access_token'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import { CircularProgress, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 92px;
  margin-bottom: 36px;
`

const StyledPromocode = styled(Input)`
  margin-top: 0 !important;
`

const Discounts = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  margin: 0 0 10px 0;
  padding-bottom: 20px;
  gap: 8px;
`

const DiscountRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const DiscountRowPrice = styled.div`
  display: flex;
  gap: 15px;
`

const RemoveButton = styled(IconButton)`
  padding: 0 !important;

  &:hover {
    background: transparent !important;
  }

  svg {
    color: #ff0000;
    width: 15px;
  }
`

export const CouponForm = () => {
  const {
    validatedPromocode,
    setValidatedPromocode,
    discountAmount,
    totalPrice,
  } = useSubscriptions()
  const { t } = useTranslation()
  const { openNotification } = useNotify()
  const [promocode, setPromocode] = useState('')
  const { store, currency, updateSession } = useCheckoutInfo()

  const { doRequest: validatePromocode, loading } = useRequest({
    url: '/checkout/coupon/validate',
    method: 'get',
    onError: () => {
      openNotification({
        type: 'error',
        message: t('coupon-form.code-not-validated'),
      })
      setPromocode('')
    },
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (validatedPromocode) {
      setValidatedPromocode('')
      updateSession({ coupon: '' })
      return
    }
    if (!promocode) return

    const result = await validatePromocode({
      storeId: store?.id,
      token,
      code: promocode,
    })

    if (!result) {
      openNotification({
        type: 'error',
        message: `${t('cart.coupon.error.message')}`,
      })
    }

    switch (result?.status) {
      case 'redeemed': {
        openNotification({
          type: 'error',
          message: `${t('cart.coupon.redeemed.message')}`,
        })
        break
      }
      case 'archived': {
        openNotification({
          type: 'error',
          message: `${t('cart.coupon.archived.message')}`,
        })
        break
      }
      case 'active':
      case 'not_redeemed': {
        setValidatedPromocode(promocode)
        updateSession({ coupon: promocode })
        openNotification({
          type: 'success',
          message: `${t('cart.coupon.not-redeemed.message')}`,
        })
        break
      }
      default:
        console.log('default case')
    }

    setPromocode('')
  }

  const buttonText = validatedPromocode
    ? t('cart.coupon.promocode.remove')
    : t('cart.coupon.promocode.add')

  return (
    <div>
      {!!validatedPromocode && (
        <Discounts>
          <Title size={4}>{t('cart.discount.title')}</Title>
          <DiscountRow>
            <Text size={3}>{validatedPromocode || promocode}</Text>
            <DiscountRowPrice>
              <Title size={5}>
                {isNaN(totalPrice) ? (
                  <CircularProgress size={14} />
                ) : (
                  formatPrice(-discountAmount / 100, currency)
                )}
              </Title>
              <RemoveButton onClick={(e) => handleSubmit(e)}>
                <ClearIcon />
              </RemoveButton>
            </DiscountRowPrice>
          </DiscountRow>
        </Discounts>
      )}
      <StyledForm onSubmit={(e) => handleSubmit(e)}>
        <StyledPromocode
          value={promocode}
          onChange={(e) => setPromocode(e.target.value)}
          placeholder={t('cart.coupon.promocode.placeholder')}
          name="promocode"
          disabled={!!validatedPromocode}
        />
        <Button type="submit" style={{ backgroundColor: 'rgb(61, 65, 54)' }}>
          {loading ? (
            <CircularProgress style={{ color: 'white' }} size={16} />
          ) : (
            buttonText
          )}
        </Button>
      </StyledForm>
    </div>
  )
}

import React from 'react'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useWindowDimensions from 'hooks/windowDimensions/useWindowDimensions'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 8px;
`

const Tabs = ({ items, selected, setSelected, disabledTabs }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const isMobile = width <= 768 // 768 is "md" in standard media queries
  const value = items.findIndex((item) => item.id === selected)

  return (
    <Wrapper>
      <MuiTabs
        value={value}
        onChange={(_, index) => setSelected(items[index].id)}
        variant={isMobile ? 'scrollable' : 'fullWidth'}
        style={{ width: '100%' }}
      >
        {items.map((item) => (
          <MuiTab
            key={item.id}
            label={t(item.name)}
            id={item.id}
            styles={{ minWidth: 0 }}
            disabled={disabledTabs.includes(item.id)}
          />
        ))}
      </MuiTabs>
    </Wrapper>
  )
}

export default Tabs

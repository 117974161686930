import React, { useEffect, useState } from 'react'
import { sendEvent } from 'helpers/analytics'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import { OverlayLoader } from 'components/atoms/overlay-loader'

const ThankYou = () => {
  const { subscriptions } = useSubscriptions()
  const [eventSent, setEventSent] = useState(false)

  const redirectUrl =
    subscriptions[0]?.redirectUrl || 'https://fitforme.com/thank-you'

  useEffect(() => {
    if (!subscriptions.length) return
    if (eventSent) return

    sendEvent('purchase')
    setEventSent(true)

    setTimeout(() => {
      window.location.href = redirectUrl
    }, 4000)
  }, [redirectUrl, eventSent, subscriptions])

  return <OverlayLoader />
}

export default ThankYou

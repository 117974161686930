import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useRequest from 'hooks/request/useRequest'
import useNotify from 'hooks/notify/useNotify'
import * as sanitize from 'sanitize-html'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { OTPForm } from './otp-form'
import { NewCustomerForm } from './new-customer-form'
import { BaseForm } from './base-form'
import { SelectAccountForm } from './select-account-form'
import { ExistingCustomerForm } from './existing-customer-form'
import styled from 'styled-components'
import useGlobalLoader from 'hooks/globalLoader/useGlobalLoader'
import { FirstOTPForm } from './first-otp-form'

export const AUTH_VIEWS = {
  EXISTING: 'existing',
  NEW: 'new',
  BASE: 'base',
  OTP: 'otp',
  FIRST_OTP: 'first_otp',
  CHOOSE_ACCOUNT: 'choose_account',
}

const authComponents = {
  existing: ExistingCustomerForm,
  new: NewCustomerForm,
  base: BaseForm,
  otp: OTPForm,
  first_otp: FirstOTPForm,
  choose_account: SelectAccountForm,
}

export const LinkButton = styled.button`
  border: none;
  background-color: transparent;
  display: inline;
  font-size: inherit;
  line-height: inherit;
  color: #90c53c;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: white;
  transition: all 0.2s ease;
  transition-delay: 0.15s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'scale(1)' : 'scale(0.98)')};
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  width: calc(100% - 40px);
  max-width: 500px;
  margin: 0px auto;
  transition: all 0.18s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'scale(1)' : 'scale(0.97)')};
`

const AuthForm = () => {
  const { setLoading } = useGlobalLoader()
  const [getParamHandled, setGetParamsHandled] = useState(false)
  const { t } = useTranslation()
  const { openNotification } = useNotify()
  const { sessionValuesHandled } = useCheckoutInfo()
  const [authResponse, setAuthResponse] = useState({})
  const [view, _setView] = useState(AUTH_VIEWS.NEW)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [visible, setVisible] = useState(true)

  const { doRequest: doLoginOtp, loading: loadingLoginOtp } = useRequest({
    url: '/api/customer/otp/auth',
    method: 'post',
    onSuccess: (res) => {
      setAuthResponse(res)
      setView(AUTH_VIEWS.CHOOSE_ACCOUNT)

      setTimeout(() => setLoading(false), 1000)
    },
    onError: () => {
      setLoading(false)
      openNotification({
        type: 'error',
        messageKey: t('error.otp-is-not-valid'),
      })
    },
  })

  useEffect(() => {
    if (getParamHandled || loadingLoginOtp || !sessionValuesHandled) return

    const urlParams = new URLSearchParams(window.location.search)

    const otp = sanitize(urlParams.get('otp'))
    const email = sanitize(urlParams.get('email'))

    if (otp && email && otp !== 'null' && email !== 'null') {
      setLoading(true)
      setGetParamsHandled(true)
      doLoginOtp({ password: otp, email, eventUser: 'Checkout' })

      urlParams.delete('otp')
      urlParams.delete('email')
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlParams.toString()}`
      )
    }
  }, [
    doLoginOtp,
    loadingLoginOtp,
    getParamHandled,
    sessionValuesHandled,
    setLoading,
  ])

  const AuthComponent = authComponents[view]

  const setView = (val) => {
    setVisible(false)
    setTimeout(() => {
      _setView(val)
      setVisible(true)
    }, 300)
  }

  return (
    <Wrapper visible={visible}>
      <AuthComponent
        setView={setView}
        email={email}
        setEmail={setEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        password={password}
        setPassword={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
        authResponse={authResponse}
        setAuthResponse={setAuthResponse}
        visible={visible}
      />
    </Wrapper>
  )
}

export default AuthForm

import React, { useState, useCallback, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Select, Button } from 'ffm-ui'

import useRequest from 'hooks/request/useRequest'
import useNotify from 'hooks/notify/useNotify'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import useCustomer from 'hooks/customer/useCustomer'
import { sendEvent } from 'helpers/analytics'
import { StyledForm } from '.'

export const SelectAccountForm = ({ email, authResponse, visible }) => {
  const { t } = useTranslation()
  const { openNotification } = useNotify()
  const { store, setShowAuth } = useCheckoutInfo()
  const {
    setCustomerCBId,
    setPersonalDetails,
    setBillingAddress,
    setShippingAddress,
    accountId,
    setAccountId,
  } = useCustomer()
  const [accounts, setAccounts] = useState([])
  const [storeItems, setStoreItems] = useState([])

  const { doRequest: fetchAccounts, loading } = useRequest({
    url: '/api/customer/accounts',
    method: 'get',
  })

  const handleSetCustomerDetails = useCallback(
    (customerId) => {
      const user = authResponse.user
      const data = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }
      setPersonalDetails((prev) => ({ ...prev, ...data }))
      setShippingAddress((prev) => ({ ...prev, ...data }))
      setBillingAddress((prev) => ({ ...prev, ...data }))

      setShowAuth(false)

      setCustomerCBId(customerId)
    },
    [
      setCustomerCBId,
      setPersonalDetails,
      setShippingAddress,
      setBillingAddress,
      authResponse,
      setShowAuth,
    ]
  )

  useEffect(() => {
    sendEvent('sign_in')
  }, [])

  useEffect(() => {
    fetchAccounts().then((accountResponse) => {
      if (!accountResponse) return
      const filteredAccounts = accountResponse.filter(
        (item) => item.storeId === store.id
      )
      setAccounts(filteredAccounts)

      if (filteredAccounts.length === 1) {
        // automatically select the single account
        handleSetCustomerDetails(filteredAccounts[0].chargeBeeId)
        setAccountId(filteredAccounts[0].id)
      } else if (filteredAccounts.length > 1) {
        // allow customer to select an account form the list
        const accounts = filteredAccounts.map((item, index) => ({
          val: item.id,
          label: `${item.storeName} #${index + 1}`,
        }))

        setStoreItems(accounts)
        setAccountId(accounts[0].val)
      } else {
        // if there is no account for a given store we treat customer as a new
        openNotification({
          type: 'info',
          messageKey: t('info.dont-have-account'),
        })
        setShowAuth(false)
      }
    })
  }, [
    email,
    fetchAccounts,
    handleSetCustomerDetails,
    setShowAuth,
    store,
    openNotification,
    t,
    setAccountId,
  ])

  const selectAccount = useCallback(() => {
    const selectedAccount = accounts.find((item) => item.id === accountId)
    handleSetCustomerDetails(selectedAccount?.chargeBeeId)
  }, [accounts, handleSetCustomerDetails, accountId])

  if (loading) {
    return <CircularProgress />
  }

  return (
    <StyledForm visible={visible}>
      <Select
        options={storeItems.map((item) => ({
          label: item.label,
          value: item.val,
        }))}
        value={accountId}
        onChange={(e) => setAccountId(e.target.value)}
        label={t('auth.store.title')}
        fullWidth
      />

      <Button
        type="submit"
        fullWidth
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          selectAccount()
        }}
      >
        {t('auth.continue')}
      </Button>
    </StyledForm>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import '@adyen/adyen-web/dist/adyen.css'
import useGlobalLoader from 'hooks/globalLoader/useGlobalLoader'
import { Button } from 'ffm-ui'
import styled from 'styled-components'
import { ReactComponent as PaypalIcon } from 'images/paypal.svg'

const StyledButton = styled(Button)`
  span {
    background-color: #ffc439 !important;
  }
  svg {
    z-index: 1 !important;
  }
  &:hover {
    span {
      background-color: #ffc439 !important;
      filter: brightness(0.95);
    }
  }
`

const StyledPaypalIcon = styled(PaypalIcon)`
  height: 24px;
`

const PaypalWrapper = styled.div`
  order: 1;
  margin-bottom: 40px;
`

export const PaypalButton = ({ onClick, loading, adyen }) => {
  const { setLoading } = useGlobalLoader()
  const [open, setOpen] = useState(false)
  const paypalButtonRef = useRef()

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  useEffect(() => {
    const container = document.querySelector('#adyen')
    const handleClose = (e) => {
      if (!paypalButtonRef?.current?.contains(e.target)) {
        setOpen(false)
      }
    }

    container.addEventListener('click', handleClose)

    return () => container.removeEventListener('click', handleClose)
  }, [])

  if (!adyen) return null

  return (
    <PaypalWrapper className="paypal-wrapper" ref={paypalButtonRef}>
      <div
        className="adyen-checkout__payment-method adyen-checkout__payment-method--paypal adyen-checkout__payment-method--credit"
        aria-labelledby="button-paypal"
        onClick={() => {
          adyen?.components[0]?.dropinRef?.setActivePaymentMethod('')

          setOpen(true)
        }}
        role="button"
        style={{ border: 'none', background: 'transparent' }}
      >
        <div
          className="adyen-checkout__payment-method__header"
          disabled={loading}
        >
          <button
            className="adyen-checkout__payment-method__header__title"
            id="button-paypal"
            aria-label="Paypal"
            aria-controls="container-paypal"
            aria-expanded="false"
          >
            <span
              className={[
                'adyen-checkout__payment-method__radio',
                open && 'adyen-checkout__payment-method__radio--selected',
              ].join(' ')}
              aria-hidden="true"
            />
            <span
              className="adyen-checkout__payment-method__image__wrapper adyen-checkout__payment-method__image__wrapper--outline"
              style={{ marginRight: '8px' }}
            >
              <img
                className="adyen-checkout__payment-method__image adyen-checkout__image adyen-checkout__image--loaded"
                src="https://checkoutshopper-test.adyen.com/checkoutshopper/images/logos/paypal.svg"
                alt="Paypal"
                focusable="false"
              />
            </span>
            <span className="adyen-checkout__payment-method__name">PayPal</span>
          </button>
        </div>
      </div>
      {!!open && (
        <StyledButton fullWidth onClick={onClick}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledPaypalIcon />
          </div>
        </StyledButton>
      )}
    </PaypalWrapper>
  )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'

import useCustomer from 'hooks/customer/useCustomer'
import useRequest from 'hooks/request/useRequest'

import AddressTooltipContent from './addressTooltipContent'

const AddressBoxHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;

  &:empty {
    margin: 0;
  }
`

const ListElement = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 12px;
  font-style: italic;
  margin: 0;

  li {
    margin: 5px 0;
  }
`

const AddressBoxContent = styled.div`
  padding: 7px;
  border: ${(props) =>
    props.isSelected ? '2px solid #90c53c' : '2px solid rgba(0, 0, 0, 0.6)'};
  border-radius: 5px;
  cursor: pointer;
`

const ignoredAddressKeys = new Set([
  'object',
  'validation_status',
  'email',
  'company',
])

const filterUniqueAddresses = (arr) => {
  const seen = new Set()

  return arr.filter((obj) => {
    const key = JSON.stringify(
      Object.keys(obj)
        .filter((k) => !ignoredAddressKeys.has(k))
        .sort()
        .map((k) => [k, obj[k]])
    )

    if (seen.has(key)) return false

    seen.add(key)
    return true
  })
}

const AddressBox = ({ countries, handleSelectAddress }) => {
  const { accountId } = useCustomer()
  const [customerAddresses, setCustomerAddresses] = useState([])

  const {
    doRequest: fetchCustomerAddresses,
    isFetched: isFetchedCustomerAddresses,
    loading: isLoadingCustomerAddresses,
  } = useRequest({
    url: `/api/customer/accounts/${accountId}/shipping-addresses`,
    method: 'get',
    onSuccess: (addresses) => {
      const unique = filterUniqueAddresses(addresses)
      setCustomerAddresses(unique.map((item) => ({ ...item, selected: false })))
    },
  })

  useEffect(() => {
    if (
      accountId &&
      !isFetchedCustomerAddresses &&
      !isLoadingCustomerAddresses
    ) {
      fetchCustomerAddresses()
    }
  }, [
    accountId,
    fetchCustomerAddresses,
    isFetchedCustomerAddresses,
    isLoadingCustomerAddresses,
  ])

  const handleClick = (item, key) => {
    setCustomerAddresses((prev) => {
      return prev.map((item, index) => ({ ...item, selected: index === key }))
    })
    handleSelectAddress(item, key)
  }

  return (
    <AddressBoxHolder>
      {customerAddresses.map((item, key) => {
        const country = countries.find((el) => el.value === item.country)

        return (
          <Tooltip title={AddressTooltipContent(item, country)} arrow key={key}>
            <AddressBoxContent
              isSelected={item.selected}
              onClick={() => handleClick(item, key)}
            >
              <ListElement>
                <li>
                  <b>Country</b>: {country?.label || '-'}
                </li>
                <li>
                  <b>City</b>: {item.city || '-'}
                </li>
              </ListElement>
            </AddressBoxContent>
          </Tooltip>
        )
      })}
    </AddressBoxHolder>
  )
}

export default AddressBox

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { contentWidth } from 'helpers/style-constants'
import useIsVisible from 'hooks/visible/useIsVisible'
import useLanguage from 'hooks/language/useLanguage'

import { ReactComponent as CheckIcon } from 'images/check.svg'
import { ReactComponent as ProfileIcon } from 'images/profile.svg'
import ContactSupport from '@mui/icons-material/ContactSupport'

import { ReactComponent as Arrow } from 'images/arrow.svg'
import { LogoLink } from 'components/atoms/logo-link'

const Items = styled.ul`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  height: 100%;
  margin: 0;
  padding: 0 40px 0 0;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: -20px;
    top: 0;
    background: #a2cd67;
    transform: skew(333deg);
    z-index: 0;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 12px;
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 200;
  position: relative;
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 8px;
`

const Top = styled.div`
  height: 30px;
  position: relative;
  background: #90c449;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 60px;

  &::after {
    content: '';
    width: 55%;
    height: 100%;
    position: absolute;
    left: -20px;
    top: 0;
    background: #a2cd67;
    transform: skew(333deg);
    z-index: 0;
  }
`

const TopContent = styled.div`
  max-width: ${contentWidth};
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.isErrorPage ? 'flex-end' : 'space-between'};
  z-index: 1;
`

const ProfileLink = styled.a`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: auto;
`

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: 4px;
  height: 14px;
  width: 14px;
`

const Bottom = styled.div`
  padding: 0 60px;
`

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  margin: 0 auto;
  max-width: ${contentWidth};
  width: 100%;
`

const Contacts = styled.div`
  color: #90c53c;
  display: flex;
  gap: 12px;
  font-size: 16px;
  line-height: 16px;

  & svg {
    width: 20px;
    height: 20px;
  }
`

const ContactItem = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`

const Link = styled.a`
  color: #90c53c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const SelectButton = styled.button`
  height: 27px;
  background: ${({ color }) =>
    color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.14)'};
  border-radius: 20px;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 8px 0 10px;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.18);
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.18)'};
  }

  &:active {
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.3)'};
  }

  &:disabled {
    background: ${({ color }) =>
      color === 'green' ? '#90C53C' : 'rgba(255, 255, 255, 0.4)'};
    cursor: initial;
    pointer-events: none;
  }
`

const SelectHolder = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 48px;
`

const ArrowIcon = styled(Arrow)`
  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  transition: transform 150ms ease-in;
  width: 8px;
  height: 4px;
  margin-left: 4px;
  position: absolute;
  right: 8px;
`

const Item = styled.li`
  padding: 10px;
  background: white;
  list-style: none;
  border-bottom: 1px solid #ededed;
  color: black;

  &:last-of-type {
    border: none;
  }

  &:hover {
    background: #90c53c;
    color: white;
  }
`

const LangItems = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 5px 9px 15px 2px rgba(0, 0, 0, 0.21);
  z-index: 999;
`

const LangAndProfileHolder = styled.div`
  display: flex;
  gap: 15px;
`

export const Header = ({ isErrorPage = false }) => {
  const { store } = useCheckoutInfo()
  const { isVisible, setIsVisible, ref } = useIsVisible(false)
  const { listOfLang, lang, setLang } = useLanguage()
  const { t } = useTranslation()
  const items = [
    t('checkout-header.one-capsule'),
    t('checkout-header.made-in-australia'),
    t('checkout-header.free-shipping'),
  ]

  return (
    <Wrapper>
      <Top>
        <TopContent isErrorPage={isErrorPage}>
          {!store?.name?.includes('International') && (
            <Items>
              {items.map((item) => (
                <ListItem key={item}>
                  <StyledCheckIcon /> {item}
                </ListItem>
              ))}
            </Items>
          )}
          <LangAndProfileHolder>
            <SelectHolder ref={ref}>
              <SelectButton
                type="button"
                onClick={() => setIsVisible(!isVisible)}
                color="green"
              >
                {lang}
                <ArrowIcon open={isVisible} />
                {isVisible && (
                  <LangItems>
                    {Object.keys(listOfLang).map((key) => {
                      return (
                        <Item
                          key={key}
                          onClick={() => {
                            setLang(key)
                            setIsVisible(false)
                          }}
                        >
                          {key}
                        </Item>
                      )
                    })}
                  </LangItems>
                )}
              </SelectButton>
            </SelectHolder>
            <ProfileLink href="https://my.fitforme.com">
              <StyledProfileIcon /> {t('checkout-header.my-account')}
            </ProfileLink>
          </LangAndProfileHolder>
        </TopContent>
      </Top>
      <Bottom>
        <BottomContent>
          <LogoLink />
          <Contacts>
            <ContactItem>
              <ContactSupport />
              <Link
                href={t('checkout-header.contact-ffm-link')}
                target="_blank"
              >
                {t('checkout-header.contact-ffm')}
              </Link>
            </ContactItem>
          </Contacts>
        </BottomContent>
      </Bottom>
    </Wrapper>
  )
}

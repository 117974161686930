import React, { useCallback, useMemo, useState } from 'react'
import useRequest from 'hooks/request/useRequest'
import { Button } from 'ffm-ui'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/text'
import useNotify from 'hooks/notify/useNotify'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { token } from 'hooks/request/access_token'
import { formatDateForAPI } from 'helpers/format'
import useCustomer from 'hooks/customer/useCustomer'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import useGlobalLoader from 'hooks/globalLoader/useGlobalLoader'
import styled from 'styled-components'
import { getChargebeeSubscriptions, getSuccessMessage } from './utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const WithoutPayment = ({ redirectUrl }) => {
  const [clicked, setClicked] = useState(false)
  const { setLoading } = useGlobalLoader()
  const { t } = useTranslation()
  const { validatedPromocode, subscriptions } = useSubscriptions()
  const { billingAddress, shippingAddress, personalDetails, customerCBId } =
    useCustomer()
  const { store, utmMedium, utmSource, sessionId, session } = useCheckoutInfo()
  const { openNotification } = useNotify()

  const createSubscriptionsUrl = useMemo(() => {
    const params = {
      storeId: store?.id,
      token,
      customerId: customerCBId,
      sessionId,
    }
    let url = 'checkout/subscription?'
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url += `&${key}=${params[key]}`
      }
    })
    return url
  }, [store, customerCBId, sessionId])

  const { doRequest: createMultipleSubscriptions } = useRequest({
    url: createSubscriptionsUrl,
    method: 'post',
    onError: () => setClicked(false),
  })

  const { doRequest: setCompletedAt } = useRequest({
    url: `/checkout-session/complete?sessionId=${sessionId}`,
    method: 'post',
  })

  const customer = useMemo(() => {
    return {
      email: personalDetails?.email,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      cf_surgery_date: formatDateForAPI(personalDetails?.surgeryDate),
      cf_surgery_type_v2: personalDetails?.surgeryType,
      cf_clinic_v2: personalDetails?.clinic,
      cf_surgeon_name: personalDetails?.surgeonName,
      cf_surgery_date_unknown: personalDetails?.surgeryDateUnknown,
      cf_date_of_birth: formatDateForAPI(personalDetails?.dateOfBirth),
      phone: personalDetails?.phone?.replace(/\s/g, ''),
      cs_marketing: personalDetails?.marketingConsent,
    }
  }, [personalDetails])

  const createSubscription = useCallback(async () => {
    setClicked(true)
    if (subscriptions.length) {
      const cbSubscriptions = getChargebeeSubscriptions({
        subscriptions,
        customer,
        billingAddress,
        shippingAddress,
        utmMedium,
        utmSource,
        validatedPromocode,
        customerCBId,
        comment: session?.data?.comment,
        store,
      })

      if (cbSubscriptions.length) {
        setLoading(true)

        try {
          const res = await createMultipleSubscriptions(cbSubscriptions)
          if (res) {
            openNotification({
              type: 'success',
              message: t(getSuccessMessage(subscriptions)),
            })

            if (sessionId) {
              await setCompletedAt()
            }

            window.location.href = redirectUrl
          } else {
            setLoading(false)
          }
        } catch (err) {
          setLoading(false)
          setClicked(false)
          console.error('error while creating subscriptions: ', err)
        }
      }
    }
  }, [
    redirectUrl,
    billingAddress,
    createMultipleSubscriptions,
    openNotification,
    shippingAddress,
    validatedPromocode,
    subscriptions,
    customer,
    utmMedium,
    utmSource,
    setCompletedAt,
    customerCBId,
    sessionId,
    setLoading,
    session,
    store,
    t,
  ])

  return (
    <Wrapper>
      <Text size={2}> {t('checkout.almost-there-start')}</Text>
      <Button disabled={clicked} fullWidth onClick={createSubscription}>
        {t('checkout.request-start')}
      </Button>
    </Wrapper>
  )
}

export default WithoutPayment

import { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

export default function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const onResize = () => setDimensions(getWindowDimensions())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return dimensions
}

import React from 'react'
import PersonalDetails from 'components/pages/personal-details'
import ShippingDetails from 'components/pages/shipping-details'
import CreateSubscription from 'components/pages/create-subscription'

const routes = [
  {
    id: '/personal-details',
    exact: true,
    main: (props) => <PersonalDetails {...props} />,
    name: 'checkout.personal-details',
  },
  {
    id: '/shipping-details',
    exact: true,
    main: (props) => <ShippingDetails {...props} />,
    name: 'checkout.shipping-details',
  },
  {
    id: '/create-subscription',
    exact: true,
    main: (props) => <CreateSubscription {...props} />,
    name: 'checkout.complete-order',
  },
]

export default routes

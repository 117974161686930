import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'
import Cart from '../../molecules/cart'
import { Header } from '../../organisms/header'
import routes from './routes'
import { contentWidth } from 'helpers/style-constants'
import Tabs from 'components/atoms/tabs'
import Title from 'components/atoms/title'

import Grid from '@mui/material/Grid'
import { breakpoints } from 'helpers/style-constants'
import { HeaderMobile } from '../../organisms/header-mobile'
import { isMobile } from 'helpers/helper'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import AuthForm from 'components/organisms/auth-form'
import useCustomer from 'hooks/customer/useCustomer'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage'
import { formatPrice } from 'helpers/format'
import SessionExpired from 'components/pages/session-expired'
import PromptModal from 'components/molecules/prompt-modal'

const Page = styled.div`
  width: 100vw;
  min-height: 100vh;
`

const Wrapper = styled.div`
  padding: 15px 15px 0px 15px;
  background: #fafafa;
  min-height: calc(100vh - 70px);

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 15px 60px 0px 60px;
    min-height: calc(100vh - 160px);
  }
`

const Content = styled.div`
  max-width: ${contentWidth};
  margin: 0 auto;
`

const Main = styled.main`
  margin: 0 auto;
  max-width: 835px;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
`

const Tab = styled.div`
  padding: 0 2px 20px 2px;

  &::-webkit-scrollbar-thumb {
    display: none;
  }
`

const CartColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const Sidebar = styled(Grid)`
  width: 360px;
  display: none;

  @media screen and (min-width: ${breakpoints.xl}) {
    display: block;
  }
`

const MobileCartToggle = styled.div`
  padding: 18px 24px;
  position: relative;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);

  &:before {
    position: absolute;
    content: '';
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
    ${({ open }) =>
      `border-${open ? 'bottom' : 'top'}: solid 6px rgba(0, 0, 0, 0.6)`};

    height: 0;
    width: 0;
    right: 0;
    top: 25px;
  }
`

const MobileCartToggleHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const MobileCartHolder = styled.div`
  background-color: #ffffff;
`

const MobileCart = styled.div`
  overflow: hidden;
  max-height: ${({ open }) => (open ? '1000px' : '0px')};
  transition: ${({ open }) =>
    open ? 'max-height 0.25s ease-in' : 'max-height 0.15s ease-out'};
`

const Link = styled.a`
  color: #90c53c;
  margin: 0 0 16px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  margin: 15px 0px 20px;
  background: #fafafa;

  @media (max-width: ${breakpoints.xl}) {
    margin: 15px 0px 30px;
  }
`

const MobileCartWrapper = styled(Grid)`
  @media screen and (min-width: ${breakpoints.xl}) {
    display: none;
  }
`

const SidebarTitle = styled(Sidebar)`
  display: none;

  @media screen and (min-width: ${breakpoints.xl}) {
    display: block;
  }
`

const Price = styled.span`
  font-weight: 600;
  margin-right: 24px;
`

const CircularProgressHolder = styled(CircularProgress)`
  margin-right: 24px;
`

const Checkout = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [cartOpen, setCartOpen] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [nextUrl, setNextUrl] = useState('')
  const { sessionId, showAuth, currency, isSessionExpired, session } =
    useCheckoutInfo()
  const { setShippingIsBilling, customerCBId } = useCustomer()
  const [completedTabs, setCompletedTabs] = useSessionStorage(
    'completedTabs',
    []
  )
  const { totalPrice } = useSubscriptions()

  useEffect(() => {
    if (customerCBId) {
      // billing address is not needed for existing customer
      setShippingIsBilling(false)
    }
  }, [customerCBId, setShippingIsBilling])

  const hiddenTabs = useMemo(() => {
    const tabs = new Set()

    if (customerCBId) {
      tabs.add('/personal-details')
    }

    return tabs
  }, [customerCBId])

  const filteredRoutes = useMemo(
    () => routes.filter((item) => !hiddenTabs.has(item.id)),
    [hiddenTabs]
  )

  const disabledTabs = useMemo(() => {
    if (customerCBId) {
      if (!completedTabs.includes('/shipping-details')) {
        return ['/create-subscription']
      }
    } else {
      if (!completedTabs.includes('/personal-details')) {
        return ['/shipping-details', '/create-subscription']
      }

      if (!completedTabs.includes('/shipping-details')) {
        return ['/create-subscription']
      }
    }

    return []
  }, [customerCBId, completedTabs])

  useEffect(() => {
    if (hiddenTabs.has(location.pathname)) {
      //  if customer landed on hiden tab
      //  we redirect him to first available route
      navigate(filteredRoutes[0].id + location.search)
    }
  }, [location, hiddenTabs, filteredRoutes, navigate, disabledTabs])

  useEffect(() => {
    if (session.completed) {
      navigate('/thanks' + location.search)
    }
  }, [session, navigate, location])

  useEffect(() => {
    setIsDirty(false)
    setNextUrl('')
  }, [location.pathname])

  if (!sessionId) {
    return null
  }

  if (isSessionExpired) {
    return <SessionExpired />
  }

  return (
    <Page>
      {!isMobile() ? <Header /> : <HeaderMobile />}

      <Wrapper>
        <Content>
          <Grid container spacing={2}>
            <MobileCartWrapper item xs={12} sm={12} md={12}>
              <Link
                href={t('checkout-header.contact-ffm-link')}
                target="_blank"
              >
                {t('checkout-header.contact-ffm')}
              </Link>
              <MobileCartHolder>
                <MobileCartToggleHolder
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setCartOpen((cur) => !cur)
                  }}
                >
                  <MobileCartToggle role="button" open={cartOpen}>
                    {t('cart.mobile.toggle.text')}
                  </MobileCartToggle>
                  {!Number.isInteger(totalPrice) ? (
                    <CircularProgressHolder size={20} />
                  ) : (
                    <Price>{formatPrice(totalPrice / 100, currency)}</Price>
                  )}
                </MobileCartToggleHolder>

                <MobileCart open={cartOpen}>
                  <Cart />
                </MobileCart>
              </MobileCartHolder>
            </MobileCartWrapper>
          </Grid>

          <Sticky>
            <Grid alignItems="center" container spacing={2}>
              <Grid item sm xs>
                {!showAuth && (
                  <Tabs
                    items={filteredRoutes}
                    selected={location.pathname}
                    disabledTabs={disabledTabs}
                    setSelected={(pathname) => {
                      if (isDirty) {
                        // confirm leaving form with changes
                        setNextUrl(pathname + location.search)
                      } else {
                        navigate(pathname + location.search)
                      }
                    }}
                  />
                )}
              </Grid>

              <SidebarTitle item>
                <Title size={2}>{t('checkout.order-summary')}</Title>
              </SidebarTitle>
            </Grid>
          </Sticky>

          <Grid container spacing={2} alignItems={'stretch'}>
            <Grid item md sm xs alignItems={'stretch'}>
              {!showAuth ? (
                <Main>
                  <Tab>
                    <Outlet context={{ setCompletedTabs, setIsDirty }} />
                  </Tab>
                </Main>
              ) : (
                <AuthForm />
              )}
            </Grid>

            <Sidebar item>
              <CartColumn>
                <Cart />
              </CartColumn>
            </Sidebar>
          </Grid>

          <PromptModal
            open={nextUrl}
            onConfirm={() => navigate(nextUrl)}
            onCancel={() => setNextUrl(false)}
          />
        </Content>
      </Wrapper>
    </Page>
  )
}

export default Checkout

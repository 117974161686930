import React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'helpers/style-constants'
import { Header } from 'components/organisms/header'

const Wrapper = styled.div`
  height: calc(100vh - 233px);
  overflow-y: auto;
  margin: 72px 0;

  @media (max-width: ${breakpoints.lg}) {
    height: calc(100vh - 110px);
    margin: 20px auto;
  }
`

export const ErrorPageLayout = ({ children }) => (
  <>
    <Header isErrorPage />
    <Wrapper>{children}</Wrapper>
  </>
)

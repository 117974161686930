import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'ffm-ui'
import Text from 'components/atoms/text'
import Title from 'components/atoms/title'
import { AUTH_VIEWS, LinkButton, StyledForm } from '.'
import useRequest from 'hooks/request/useRequest'
import { isEmail } from 'helpers/helper'

export const ExistingCustomerForm = ({
  setView,
  email,
  setEmail,
  emailError,
  setEmailError,
  visible,
}) => {
  const { t } = useTranslation()
  const [haveAccount, setHaveAccount] = useState(false)
  const { doRequest: checkIfAccountExists } = useRequest({
    url: '/api/customer/otp/check-email',
    method: 'post',
  })

  return (
    <StyledForm visible={visible}>
      <Title style={{ margin: 0 }} size={3}>
        {t('auth.already-have-an-account')}
      </Title>
      <Input
        required
        label={t('user-data.email')}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          setEmailError('')
          setHaveAccount(false)
        }}
        type="email"
        name="email"
        placeholder={t('user-data.email')}
        error={emailError && t(emailError)}
      />
      <Button
        fullWidth
        disabled={emailError && !haveAccount}
        type="submit"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          if (!isEmail(email)) {
            setEmailError('auth.enter-valid-email')
            return
          }

          checkIfAccountExists({ email }).then(
            ({ exist, hasRegularPassword, isNewCustomer }) => {
              if (exist) {
                if (isNewCustomer) {
                  setView(AUTH_VIEWS.FIRST_OTP)
                } else {
                  setView(hasRegularPassword ? AUTH_VIEWS.BASE : AUTH_VIEWS.OTP)
                }
              } else {
                setEmailError('auth.dont-have-account-yet')
              }
            }
          )
        }}
      >
        {t('auth.sign-in')}
      </Button>

      <Text size={2}>
        {t('auth.new-customer')}
        &nbsp;
        <LinkButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setView(AUTH_VIEWS.NEW)
            setEmailError(null)
          }}
        >
          {t('auth.sign-up')}
        </LinkButton>
      </Text>
    </StyledForm>
  )
}

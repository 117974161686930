import { useEffect, useState, useRef, useCallback, useMemo } from 'react'

import useRequest, {
  getErrorMessage,
  useRequestErrorHandler,
} from 'hooks/request/useRequest'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import useNotify from 'hooks/notify/useNotify'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { token } from 'hooks/request/access_token'
import { formatDateForAPI } from 'helpers/format'
import useCustomer from 'hooks/customer/useCustomer'
import useSubscriptions from 'hooks/subscriptions/useSubscriptions'
import { useTranslation } from 'react-i18next'
import {
  getChargebeeSubscriptions,
  getSuccessMessage,
  minimumExpiryDate,
} from './utils'
import useGlobalLoader from 'hooks/globalLoader/useGlobalLoader'
import { sendEvent } from 'helpers/analytics'
import { trackError } from 'helpers/trackError'
import i18n from 'locales/i18n'

export const useChargebee = ({ redirectUrl }) => {
  const { setLoading } = useGlobalLoader()
  const { t } = useTranslation()
  const {
    totalPrice,
    validatedPromocode,
    subscriptions,
    totalBySubscriptionDate,
  } = useSubscriptions()
  const { billingAddress, personalDetails, shippingAddress, customerCBId } =
    useCustomer()
  const {
    store,
    utmMedium,
    utmSource,
    sessionId,
    currency,
    session,
    adyenMethods,
    productFlow,
  } = useCheckoutInfo()
  const paymentContainer = useRef(null)
  const [adyen, setAdyen] = useState()
  const [cbInstance, setCbInstance] = useState()
  const { openNotification } = useNotify()
  const [paypalLoading, setPaypalLoading] = useState(false)
  const { requestErrorHandler } = useRequestErrorHandler()
  const createSubscriptionsQueryString = useMemo(() => {
    const params = {
      storeId: store?.id,
      token,
      customerId: customerCBId,
      sessionId,
    }
    let queryString = ''
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        queryString += `&${key}=${params[key]}`
      }
    })
    return queryString
  }, [store, customerCBId, sessionId])

  const { doRequest: payWithPaypal } = useRequest({
    url: `checkout/subscription/paypal?${createSubscriptionsQueryString}`,
    method: 'post',
    onError: (error) => {
      trackError(
        `Paypal payment failed: ${getErrorMessage(error)}`,
        'Paypal payment method'
      )

      setPaypalLoading(false)

      requestErrorHandler(error)
    },
  })

  const { doRequest: payWithSepa } = useRequest({
    url: `checkout/subscription/sepa?${createSubscriptionsQueryString}`,
    method: 'post',
    onError: (error) => {
      trackError(
        `Sepa payment failed: ${getErrorMessage(error)}`,
        'Sepa payment method'
      )

      setLoading(false)

      requestErrorHandler(error)
    },
  })

  const { doRequest: createMultipleSubscriptions } = useRequest({
    url: `checkout/subscription?${createSubscriptionsQueryString}`,
    method: 'post',
    onSuccess: async (res) => {
      if (res) {
        openNotification({
          type: 'success',
          message: t(getSuccessMessage(subscriptions)),
        })
        await setCompletedAt()
        window.location.href = redirectUrl
      } else {
        openNotification({
          type: 'error',
          message: t('error.subscription-create'),
        })

        trackError('Failed to create subscriptions')

        setLoading(false)
      }
    },
  })

  const { doRequest: createPaymentIntent } = useRequest({
    url: `checkout/payment-intent?storeId=${store?.id}&token=${token}`,
    method: 'post',
  })

  const { doRequest: setCompletedAt } = useRequest({
    url: `/checkout-session/complete?sessionId=${sessionId}`,
    method: 'post',
  })

  const { doRequest: createPimcoreSubscription } = useRequest({
    url: `/api/checkout/${sessionId}/subscribe?token=${token}`,
    method: 'post',
  })

  const customer = useMemo(() => {
    return {
      email: personalDetails?.email,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      cf_surgery_date: formatDateForAPI(personalDetails?.surgeryDate),
      cf_surgery_type_v2: personalDetails?.surgeryType,
      cf_clinic_v2: personalDetails?.clinic,
      cf_surgeon_name: personalDetails?.surgeonName,
      cf_surgery_date_unknown: personalDetails?.surgeryDateUnknown,
      cf_date_of_birth: formatDateForAPI(personalDetails?.dateOfBirth),
      phone: personalDetails?.phone?.replace(/\s/g, ''),
      cs_marketing: personalDetails?.marketingConsent,
    }
  }, [personalDetails])

  const cbSubscriptions = useMemo(() => {
    const subs = getChargebeeSubscriptions({
      subscriptions,
      customer,
      billingAddress,
      shippingAddress,
      utmMedium,
      utmSource,
      validatedPromocode,
      customerCBId,
      comment: session?.data?.comment,
      store,
    })

    return subs
  }, [
    subscriptions,
    customer,
    billingAddress,
    shippingAddress,
    utmMedium,
    utmSource,
    validatedPromocode,
    customerCBId,
    session,
    store,
  ])

  const onSubmit = useCallback(
    async (state, component, adyen) => {
      if (!state.isValid || !cbInstance || !totalPrice || !adyen) {
        return
      }
      sendEvent('add_payment_info')
      setLoading(true)
      const issuer = state.data.paymentMethod.issuer
      let type = state.data.paymentMethod.type
      let intent
      switch (type) {
        case 'ideal':
        case 'sofort':
        case 'giropay':
        case 'dotpay':
        case 'directEbanking':
        case 'bankcontact': {
          if (type === 'directEbanking') {
            type = 'sofort'
          }
          const paymentIntent = await createPaymentIntent({
            payment_method_type: type,
            currency_code: currency,
            amount: totalPrice,
          })
          intent = await cbInstance.handlePayment(type, {
            paymentIntent,
            paymentInfo: { issuerBank: issuer },
          })
          break
        }
        case 'scheme':
          {
            const tdshandler = await cbInstance.load3DSHandler()
            const cardIntent = await createPaymentIntent({
              payment_method_type: 'card',
              currency_code: currency,
              amount: totalPrice,
            })

            tdshandler.setPaymentIntent(cardIntent, { adyen })

            try {
              intent = await tdshandler.handleCardPayment(
                { element: component },
                {
                  change: (x) => console.log('change', x),
                  success: (x) => console.log('success', x),
                  error: (x) => {
                    console.error('intent err: ', x)

                    trackError(
                      `3ds card payment failed: ${JSON.stringify(x)}`,
                      '3ds card payment method'
                    )
                  },
                }
              )
            } catch (err) {
              const message = err?.message ? err.message : JSON.stringify(err)
              openNotification({
                type: 'error',
                message,
              })
              console.error('error occured: ', message)

              trackError(
                `Card payment failed: ${message}`,
                'Card payment method'
              )

              setLoading(false)
            }
          }
          break
        case 'sepadirectdebit':
          try {
            let sepaResponse = null
            if (productFlow === 'v1') {
              sepaResponse = await payWithSepa({
                currency,
                paymentMethod: {
                  'sepa.ibanNumber': state.data.paymentMethod.iban,
                  'sepa.ownerName': state.data.paymentMethod.ownerName,
                  type: 'sepadirectdebit',
                },
                subscription: cbSubscriptions,
                amount: totalBySubscriptionDate?.Immediately?.total || 0,
              })
            } else {
              sepaResponse = await createPimcoreSubscription({
                iban: state.data.paymentMethod.iban,
                ownerName: state.data.paymentMethod.ownerName,
                type: 'sepadirectdebit',
                browserInfo: state.data.browserInfo || {},
              })
            }

            if (sepaResponse) {
              component.setStatus('success')
              openNotification({
                type: 'success',
                message: t(getSuccessMessage(subscriptions)),
              })
              await setCompletedAt()
              window.location.href = redirectUrl
            } else {
              component.setStatus('error', { message: t('payment.error') })
              setLoading(false)
            }
          } catch (err) {
            console.error(err)
            setLoading(false)

            trackError(`Sepa payment failed: ${err}`, 'Sepa payment')
          }

          return
        default:
          console.error('default case return: ', state)
          trackError(
            'Reached unsupported payment method',
            'Unsupported payment method'
          )

          return
      }
      if (intent) {
        let res = null

        if (cbSubscriptions.length) {
          try {
            if (productFlow === 'v1') {
              const intentSubscriptions = [...cbSubscriptions]
              intentSubscriptions[0].paymentIntent = { id: intent.id }
              res = await createMultipleSubscriptions(intentSubscriptions)
            } else {
              res = await createPimcoreSubscription({
                type: 'intent',
                paymentIntentId: intent.id,
              })
            }
          } catch {
            component.setStatus('error', { message: t('payment.error') })
            setLoading(false)
          }
        }

        if (res) {
          component.setStatus('success')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } else {
          component.setStatus('error', { message: t('payment.error') })
        }

        setLoading(false)

        return res
      } else {
        setLoading(false)
      }
    },
    [
      currency,
      redirectUrl,
      createPaymentIntent,
      createMultipleSubscriptions,
      createPimcoreSubscription,
      openNotification,
      setCompletedAt,
      t,
      payWithSepa,
      setLoading,
      totalBySubscriptionDate,
      totalPrice,
      cbInstance,
      cbSubscriptions,
      subscriptions,
      productFlow,
    ]
  )

  useEffect(() => {
    if (!store.chargeBeeKey) return
    const instance = window.Chargebee.init({
      site: store.chargeBeeWebsite,
      publishableKey: store.chargeBeeKey,
    })

    setCbInstance(instance)
  }, [store.chargeBeeKey, store.chargeBeeWebsite])

  useEffect(() => {
    if (
      !store?.adyenEnv ||
      isNaN(totalPrice) ||
      !cbInstance ||
      !adyenMethods ||
      !paymentContainer.current
    ) {
      if (adyen && isNaN(totalPrice)) {
        adyen.remove(adyen.components[0])
        setAdyen(null)
      }
      return
    }

    setAdyen((currentAdyen) => {
      const options = {
        locale: i18n.language,
        paymentMethodsResponse: adyenMethods,
        clientKey: store.adyenKey,
        environment: store.adyenEnv,
        onSubmit: (state, component) =>
          onSubmit(state, component, currentAdyen),
        setStatusAutomatically: false,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            minimumExpiryDate,
          },
        },
      }

      if (currentAdyen) {
        currentAdyen.setOptions(options)
        return currentAdyen
      }
      const checkout = new AdyenCheckout(options)
      checkout
        .create('dropin', {
          amount: totalPrice,
          currency,
          openFirstPaymentMethod: false,
        })
        .mount(paymentContainer.current)

      checkout.setOptions({
        ...options,
        onSubmit: (state, component) => onSubmit(state, component, checkout),
      })

      return checkout
    })
  }, [store, totalPrice, currency, adyenMethods, cbInstance, onSubmit, adyen])

  const handleClickPaypal = useCallback(async () => {
    setPaypalLoading(true)
    sendEvent('add_payment_info')

    let result = null

    if (productFlow === 'v1') {
      result = await payWithPaypal({
        successUrl: redirectUrl,
        errorUrl: window.location.href,
        cancelUrl: window.location.href,
        currency,
        subscription: cbSubscriptions,
        sessionId,
        amount: (totalBySubscriptionDate?.Immediately?.total || 0) / 100,
      })
    } else {
      result = await createPimcoreSubscription({
        type: 'paypal',
        successUrl: redirectUrl,
        errorUrl: window.location.href,
        cancelUrl: window.location.href,
      })
    }

    const resultUrl = result?.url || result?.redirectUrl

    if (resultUrl) {
      window.location.href = resultUrl
    } else {
      setPaypalLoading(false)
    }
  }, [
    currency,
    payWithPaypal,
    redirectUrl,
    sessionId,
    totalBySubscriptionDate,
    cbSubscriptions,
    productFlow,
    createPimcoreSubscription,
  ])

  return { paymentContainer, adyen, paypalLoading, handleClickPaypal }
}
